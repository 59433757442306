#blended-learning-desc{
  padding-right: $col + $gutter;
}

#blended-learning-chart{
  text-align: center;
}

.blended-learning-chart-item{
  &:nth-child(2){
    @include media-width($max: $medium - 1){
      margin: 40px 0;
    }
  }
}

.blended-learning-chart-picto-wrapper{
  position: relative;
  display: inline-block;
  width: 30%;
  vertical-align: top;
  line-height: 0;

  @include media-width($medium){
    display: block;
    width: auto;
    margin: 30px 0 5px;
  }

  .blended-learning-chart-item:nth-child(2) &{
    margin: 0;
  }

  .blended-learning-chart-item:nth-child(3) &{
    @include media-width($max: $medium - 1){
      margin-top: 60px;
    }
  }
}

.blended-learning-chart-arrow{
  position: absolute;
  top: 100%;
  left: 50%;
  width: 16px;
  height: 60px;
  margin-left: -8px;

  @include media-width($medium){
    top: 60px - 16px / 2 !important;
    left: 50%;
    width: 100px;
    height: 16px;
    margin-left: 60px;
  }

  @include media-width($large){
    width: 150px;
  }

  @include media-width($xlarge){
    width: 200px;
  }

  .blended-learning-chart-item:nth-child(3) &{
    top: auto;
    bottom: 100%;

    @include media-width($medium){
      right: 50%;
      bottom: auto;
      left: auto;
      margin-left: 0;
      margin-right: 60px;
    }
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    width: 2px;
    height: 100%;
    background-color: $blue;

    @include media-width($medium){
      top: 7px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }

  .in-view-active &{
    opacity: 0;
    transform: scaleY(.5);

    @include media-width($medium){
      transform: scaleX(.5);
    }
  }

  .in-view &{
    transition: transform .8s $easeOutExpo, opacity .3s;
    transform: scaleY(1);
    opacity: 1;
    transform-origin: left top;
    transition-delay: .6s;

    @include media-width($medium){
      transform: scaleX(1);
    }
  }

  .blended-learning-chart-item:nth-child(3) &{
    transform-origin: right bottom;
  }

  &::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 8px 0 8px;
    border-color: $blue transparent transparent transparent;

    @include media-width($medium){
      top: 0;
      left: 100%;
      border-width: 8px 0 8px 14px;
      border-color: transparent transparent transparent $blue;
    }

    .blended-learning-chart-item:nth-child(3) &{
      top: -14px;
      border-width: 0 8px 14px 8px;
      border-color: transparent transparent $blue transparent;

      @include media-width($medium){
        top: 0;
        left: auto;
        right: 100%;
        border-width: 8px 14px 8px 0;
        border-color: transparent $blue transparent transparent;
      }
    }
  }
}

.blended-learning-chart-picto{
  position: relative;
  display: inline-block;
  width: 70%;
  height: auto;
  border-radius: 50%;
  border: 2px solid $blue;

  &::before{
    content: "";
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 100%;
  }

  @include media-width($medium){
    width: 120px;
    height: 120px;
    padding-bottom: 0;
    line-height: 120px;

    &::before{
      content: none;
    }
  }

  .in-view-active &{
    opacity: 0;
    transform: scale(.5, .5);
  }

  .in-view &{
    transition: transform .6s $easeOutExpo, opacity .3s;
    transform: scale(1, 1);
    opacity: 1;
  }

  .blended-learning-chart-item:nth-child(2) &{
    transition-delay: .8s;
  }
}

#blended-learning-chart-blended-picto{
  width: 100%;
  background-color: $blue;

  @include media-width($medium){
    width: 180px;
    height: 180px;
  }
}

.blended-learning-chart-picto svg{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%,-50%);
  fill: $blue;

  .blended-learning-chart-item:nth-child(1) &{
    width: 20px;
    height: round((20px / 30px) * 46px);

    @include media-width($small){
      width: 30px;
      height: 46px;
    }
  }

  .blended-learning-chart-item:nth-child(2) &{
    width: 56px;
    height: round((56px / 74px) * 61px);
    fill: #fff;

    @include media-width($small){
      width: 74px;
      height: 61px;
    }

    path:last-child{
      fill: $blue;
    }
  }

  .blended-learning-chart-item:nth-child(3) &{
    width: 40px;
    height: round((40px / 74px) * 50px);

    @include media-width($small){
      width: 74px;
      height: 50px;
    }
  }

  .in-view-active &{
    opacity: 0;
    transform: translate(-50%,10%)
  }

  .in-view &{
    transition: transform .6s $easeOutExpo, opacity .2s;
    transition-delay: .2s;
    transform: translate(-50%,-50%);
    opacity: 1;
  }

  .blended-learning-chart-item:nth-child(2) &{
    transition-delay: .9s;
  }
}

.blended-learning-chart-text-wrapper{
  display: inline-block;
  width: 65%;
  padding: 0 20px;

  @include media-width($xsmall){
    padding: 0 30px;
  }

  @include media-width($medium){
    display: block;
    width: auto;
    padding: 0;
  }
}

.blended-learning-chart-title{
  display: inline-block;
  width: 50%;
  font-family: $dinbold;
  font-size: 16px;
  line-height: 24px;
  color: #000;

  @include media-width($small){
    font-size: 20px;
    line-height: 30px;
  }

  .blended-learning-chart-item:nth-child(2) &{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    color: $blue;
    font-family: $dinbold;

    @include media-width($small){
      padding-top: 25px;
      padding-bottom: 40px;
    }
  }
}

.blended-learning-chart-list{
  padding-top: 15px;
  padding-left: 0 !important;

  @include media-width($small){
    padding-top: 25px;
  }
}

.blended-learning-chart-list li{
  padding: 15px 0;
  margin-bottom: 0;
  border-top: 1px solid #e6e6e6;

  &:first-child{
    border-top: none;
  }

  &::before{
    content: none;
  }
}

