.blog-article-page-header{
  text-align: center;
}

.blog-article-page-header .page-header-img-wrapper{
  height: 265px;

  @include media-width($medium){
    height: 530px;
  }
}

@media print{
  .blog-article-page-header .page-header-img-wrapper{
    height: auto;
  }
}

.blog-article-nav{
  position: relative;
  top: (-80px - 50px);
  margin: 0 $margin;
  text-transform: uppercase;

  @include media-width($medium){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
  }
}

@media print{
  .blog-article-nav{
    margin: 0;
  }
}

.blog-article-nav-parent-page{
  position: relative;
  display: inline-block;
  padding: 0 50px;
  line-height: 50px;
  color: rgba(0,0,0,.5);
  text-transform: uppercase;
 
  font-family: $dinbold;
  z-index: 1;

  &:hover{
    color: $blue;
  }

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #161616;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .7s $easeOutExpo;
  }

  &:hover::after{
    transform: scaleX(1);
    transform-origin: left;
    transition-duration: .5s;
  }
}

@media print{
  .blog-article-nav-parent-page{
    display: none;
  }
}

.blog-article-nav-parent-page-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2ba8e2;
  z-index: -1;
  transform-origin: right;
}

.blog-article-nav-parent-page-label{
  display: inline-block;

  .blog-article-nav-parent-page:hover &{
    animation: blog-article-nav-parent-page-roll-over .5s $easeOutExpo;
  }
}

@keyframes blog-article-nav-parent-page-roll-over{
  from{
    transform: translateY(50px);
  }

  to{
    transform: translateY(0);
  }
}

.blog-article-nav-current-page{
  position: relative;
  display: flex;
  align-items: center;
  min-height: 150px;
  z-index: 1;
}

.blog-article-nav-current-page-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blue;
  z-index: -1;
  transform-origin: right;
}

.blog-article-nav-current-page-title{
  display: block;
  width: 100%;
  padding: 30px ((60px / (720px - 60px * 2)) * 100%);
  color: #fff;
  font-family: $dinblack;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: .075em;

  @include media-width($small){
    padding: 37px ($col-2 + $gutter) 40px;
    font-size: 22px;
    line-height: 30px;
  }

  @include media-width($medium){
    font-size: 26px;
    line-height: 34px;
  }

  @include media-width($large){
    font-size: 32px;
    line-height: 40px;
  }
}

.blog-article-page-content{
  padding-top: 40px;
  margin-top: (-80px - 50px);

  @include media-width($medium){
    padding-top: 60px;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    margin-top: -300px;
    // z-index: -1;
    background-color: #fff;
  }
}

.blog-article-page-content .page-content-overlay{
  top: -300px;
  height: calc(100% + 300px);
}

.blog-article-page-content .content-inner{
  display: inline-flex;
  flex-direction: column;

  @include media-width($medium){
    display: inline-block;
  }
}

.blog-article-aside{
  order: 2;
  padding-bottom: 60px;

  @include media-width($medium){
    float: left;
    width: $col-2 + $gutter;
    padding-bottom: 100px;
    margin-right: $col + $gutter;
  }
}

.blog-article-infos{
  border: 1px solid #e6e6e6;
}

.blog-article-info{
  display: block;
  padding: 22px 30px 25px;
  color: #666;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #e6e6e6;

  &:first-child{
    border-top: none;
  }

  @include media-width($medium){
    padding: ((22px / 200px) * 100%) ((30px / 200px) * 100%) ((25px / 200px) * 100%);
  }

  .blog-article-info-content {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
}

.blog-article-info-title{
 
  font-family: $dinbold;
  font-size: inherit;
}

.blog-article-aside-sticky{
  position: relative;
  overflow: hidden;
  padding-top: 40px;
}

.blog-article-shares{
  margin-bottom: 40px;
  background-color: #f2f2f2;
}

@media print{
  .blog-article-shares{
    display: none;
  }
}

.blog-article-shares-title{
  padding: 22px 30px 25px;
  font-size: 14px;
  line-height: 20px;
 
  font-family: $dinbold;
  color: #000;
  border-bottom: 1px solid #e6e6e6;

  @include media-width($medium){
    padding: ((22px / 200px) * 100%) ((30px / 200px) * 100%) ((25px / 200px) * 100%);
  }
}

.blog-article-shares-list{
  padding: 22px 30px 25px;

  @include media-width($medium){
    padding: ((23px / 200px) * 100%) ((30px / 200px) * 100%) ((17px / 200px) * 100%);
  }
}

.blog-article-shares-item{
  line-height: 40px;
}

.blog-article-print-btn{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  line-height: 50px;
 
  font-family: $dinbold;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  background-color: $blue;
  z-index: 1;

  @include media-width($min: $medium, $max: $xlarge - 1){
    font-size: 12px;
    line-height: 17px;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2ba8e2;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .7s $easeOutExpo, opacity 0s .6s;
    opacity: 0;
  }

  &:hover::before{
    transform: scaleX(1);
    transform-origin: left;
    transition: transform .7s $easeOutExpo;
    opacity: 1;
  }
}

@media print{
  .blog-article-print-btn{
    display: none;
  }
}

.blog-article-print-btn-picto{
  display: inline-block;
  width: 16px;
  height: 14px;
  margin-left: 8px;
  vertical-align: middle;
}

.blog-article-content{
  order: 1;
  padding-bottom: 60px;
  color: #666;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  @include media-width($medium){
    float: left;
    width: $col-9;
    padding-right: $col + $gutter;
    padding-bottom: 100px;
  }
}

.blog-article-content p{
  @extend .content-paragraph;
}

.blog-article-content a{
  @extend .content-link;
}

.blog-article-content b, .blog-article-content strong {
  font-family: $dinbold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-weight: normal;
}

.blog-article-content-lead{
 
  font-family: $dinbold;
}

.blog-article-content ul{
  @extend .content-list;
}

.blog-article-content li{
  @extend .content-list-item;
  margin-bottom: 15px;
}

.blog-article-content-body-note-ref{
  color: $blue !important;
  background: none !important;
 
  font-family: $dinbold;
  vertical-align: super;
  font-size: .6em;
  line-height: .6em;
}

.blog-article-content-notes{
  font-size: 14px;
}

.blog-article-content-notes a{
  font-weight: 400;
  font-family: $din;
}

.blog-article-latest{
  order: 3;
  clear: both;
  padding: 40px 0 60px;
  text-align: center;
  border-top: 1px solid #e5e5e5;

  @include media-width($medium){
    padding: 90px 0 100px;
  }
}

@media print{
  .blog-article-latest{
    display: none;
  }
}

.blog-article-latest-title{
  @extend .underlined-title;
  margin-bottom: 60px;

  @include media-width($medium){
    margin-bottom: 90px;
  }
}

