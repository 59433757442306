//media queries

@mixin media($mediaquery){
  @media #{$mediaquery}{
    @content;
  }
}

// inspired by https://github.com/sass-mq/sass-mq/blob/master/_mq.scss

@function get-mediaquery-size($size, $min: false, $max: false, $and: false){
  $media-query: 'screen ';

  @if $min {
    $media-query: '#{$media-query} and (min-#{$size}: #{$min})';
  }

  @if $max {
    $media-query: '#{$media-query} and (max-#{$size}: #{$max})';
  }

  @if $and { 
    $media-query: '#{$media-query} and #{$and}'; 
  }

  @return $media-query;
}

@mixin media-width($min: false, $max: false, $and: false){
  $media-query: get-mediaquery-size(width, $min, $max, $and);

  @media #{$media-query}{
    @content;
  }
}

@mixin media-height($min: false, $max: false, $and: false){
  $media-query: get-mediaquery-size(height, $min, $max, $and);

  @media #{$media-query}{
    @content;
  }
}

@mixin media-retina(){
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) { 
    @content;
  }
}

