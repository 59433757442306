#formations{
  background-color: #f2f2f2;
  background-image: url("../images/expertise-formation-goals.jpg");
  background-position: left top;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

#formations-objectifs{
  @include media-width($medium){
    display: flex;
  }
}

.formation-objectif{
  display: flex;
  flex-direction: column;
  padding-right: 0;
  overflow: hidden;
  background-color: #fff;
}

.formation-objectif-title{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-family: $dinbold;
  color: #000;
  border-bottom: 1px solid #e6e6e6;
}

.formation-infos{


  @include media-width($small){
    display: flex;
    flex-grow: 2;
  }
}

.formation-info{

  padding: 25px (40px / 560px) * 100% 30px;
  border-right: 1px solid #e6e6e6;

  @include media-width($small){
    float: left;
    width: 50%;
  }

  &:last-child{
    border-right: 0;
  }
}

.formation-info-title{
  margin-bottom: 25px;

  @include media-width($max: $medium - 1){
    height: auto !important;
  }
}

.formation-info-subtitle{
  margin: 20px 0;
  text-transform: uppercase;
  color: #999;
  font-family: $din;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: .1em;
}

.formation-info-text{
  font-size: 15px;
  line-height: 25px;
}

