#page-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161616;
  z-index: 10;
  visibility: hidden;
}

.page{
  font-size: 14px;
  line-height: 24px;

  @include media-width($medium){
    font-size: 16px;
    line-height: 27px;
  }

  @include media-width($large){
    font-size: 18px;
    line-height: 30px;
  }

  .js &{
    visibility: hidden;
  }
}

.page-header{
  position: relative;
  width: 100%;
  margin-top: $nav-bar-height;
  perspective: 800px;
  z-index: 4;

  @include media-width($medium){
    margin-top: 0;

    &::before{
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 135px;
      content: "";
      opacity: .8;
      z-index: 4;
      background:linear-gradient(to bottom, black 0%, rgba(0,0,0,0.2) 70%, transparent 100%);
    }
  }
}

.page-header-img-wrapper{
  position: relative;
  overflow: hidden;
  background-color: #161616;
}

.page-header-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.page-content{
  position: relative;
  background-color: #fff;
}

.page-content-overlay{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161616;
  z-index: 3;

  .js &{
    display: block;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}
