@import "ease";
@import "base";

// mixins
@import "mixins/media";
@import "mixins/font";
// @import "mixins/shapes/hexagon";


/* APPLICATION */

@import "app/vars";
@import "libs/select2";
@import "app/fonts";
@import "app/common";
@import "app/anims";

@import "app/components/nav";
@import "app/components/footer";
@import "app/components/block-btn";
@import "app/components/article-item";
@import "app/components/hexagon-header";
@import "app/components/loading";
@import "app/components/video-popin";
@import "app/components/block-background";

@import "app/pages/page";
@import "app/pages/home";
@import "app/pages/level-1";
@import "app/pages/level-2";
@import "app/pages/blog";
@import "app/pages/blog-article";
@import "app/pages/contact";
@import "app/pages/worldwide";

@import "app/content-blocks/changement";
@import "app/content-blocks/formations";
@import "app/content-blocks/blended-learning";
@import "app/content-blocks/formation-ccl";
@import "app/content-blocks/deploiement-facteurs";
@import "app/content-blocks/deploiement-approches";
@import "app/content-blocks/landing";
@import "app/content-blocks/case";
@import "app/content-blocks/team";


