#contact-page-header{
  background-color: #e6e6e6;
  z-index: 1;
}

#contact-map-container{
  height: 265px;

  @include media-width($medium){
    height: 530px;
  }
}

#contact-page-content{
  position: static;
  padding-bottom: 100px;
}

.contact-title{
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 40px;
  font-family: $droid;
  font-style: italic;
}

.contact-infos-title-2{
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 30px;
  font-family: $dinbold;
  color: $blue;
}

.contact-infos-title-3{
  font-family: $dinbold;
}

#contact-infos{
  position: relative;
  margin-top: -50px;
  color: #fff;
  // background-color: #171717;
  z-index: 2;

  @include media-width($medium){
    float: left;
    width: $col-6 + $gutter;
    margin-top: -110px;
    margin-right: $col + $gutter;
  }
}

#contact-infos-bg{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #171717;
  z-index: -1;
  transform-origin: right;
}

#contact-infos-main,
#contact-infos-access{
  padding: (55px / 600px) * 100% (60px / 600px) * 100%;
}

#contact-infos-main{
  border-bottom: 1px solid #454545;
}

#contact-infos-main a[href^=tel]{
  color: #fff; // iOS override style for auto generated phone links
}

.contact-infos-access-group{
  padding-bottom: 30px;

  &:last-child{
    padding-bottom: 0;
  }
}

.contact-infos-access-subgroup{
  padding-top: 20px;
  padding-bottom: 10px;

  .contact-infos-access-group:last-child &:last-child{
    padding-bottom: 0;
  }
}

.contact-infos-tcl-picto{
  position: relative;
  top: 4px;
  margin-right: 10px;
}

#contact-form-wrapper{
  padding-top: 80px;

  @include media-width($medium){
    float: left;
    width: $col-5;
  }
}

.contact-form-label{
  position: relative;
  display: block;
  margin-bottom: 50px;

  &::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #e6e6e6;
    z-index: 2;
  }

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $blue;
    transform-origin: right;
    transform: scaleX(0);
    opacity: 0;
    transition: transform .6s $easeOutExpo, opacity 0s .5s;
    z-index: 3;
  }

  &.focus::after{
    transition: transform .5s $easeOutExpo;
    transform-origin: left;
    transform: scaleX(1);
    opacity: 1;
  }
}

.contact-form-label-txt{
  display: block;
  color: #666;
  font-family: $dinbold;
  cursor: pointer;
  transform: translateY(42px);
  transition: transform .6s $easeOutExpo;

  .focus &{
    transform: translateY(0);
  }

  .filled &{
    transform: translateY(0);
  }

  #contact-form-subject-label &{
    transform: none;
  }
}

.contact-upload-field {
  .contact-form-label-txt{
    transform:none;
    padding-bottom: 10px;
  }

  input[type=file] {
    padding-bottom:20px;
  }
}

#contact-form input[type=text],
#contact-form input[type=email],
#contact-form textarea,
#contact-form select{
  position: relative;
  width: 100%;
  height: 60px;
  color: #666;
  font-size: inherit;
  font-weight: inherit;
  // border-bottom: 3px solid #e6e6e6;
  // border-radius: 0;
}

#contact-form input[type=text]{
  line-height: 60px;
}

#contact-form-subject-wrapper{
  position: relative;
  display: block;
  height: 100%;

  &::after{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    margin-top: 2px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #000000 transparent transparent transparent;
  }

  &.custom::after{
    content: none;
  }
}

#contact-form-subject-wrapper .select2-selection--single{
  height: 60px;
  border: none;
  line-height: 60px;

  &:focus{
    outline: none;
  }
}

#select2-contact-form-subject-container{
  height: 60px;
  padding-left: 0;
  line-height: 60px;

  &:focus{
    outline: none;
  }
}

#contact-form-subject-wrapper .select2-selection__arrow{
  top: 50%;
  margin-top: -10px;

  b{
    border-color: #000 transparent transparent transparent;
  }
}

#contact-form-subject-wrapper .select2-container--default.select2-container--open .select2-selection__arrow b{
  border-color: transparent transparent #000 transparent;
}

.select2-dropdown{
  border: 2px solid #e6e6e6;
  border-radius: 0;

  &.select2-dropdown--below{
    margin-top: -2px;
  }

  &.select2-dropdown--above{
    border-bottom: 2px solid #e6e6e6;
  }
}

.select2-results__option{
  height: 48px;
  line-height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 1px solid #e6e6e6;
}

.select2-results__option--highlighted{
  background-color: $blue !important;
}

#contact-form-subject{
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus{
    outline: none;
  }
}

#contact-form textarea{
  min-height: 200px;
  padding-top: 10px;
  line-height: inherit;
  resize: vertical;
}

#contact-form-submit-btn{
  @extends .block-btn;
  margin-top: 30px;
  width: 100%;
  height: 70px;
  padding: 0 (40px / 460px) * 100%;
  font-size: 16px;
}

#contact-form .block-btn-picto{
  line-height: 30px;
}

.contact-form-submit-btn-picto-mail{
  position: relative;
  top: -2px;
  display: inline-block;
  width: 11px;
  height: 8px;
  vertical-align: middle;

  svg{
    display: block;
  }
}

#contact-form-required-info{
  margin-top: 30px;
  font-size: 14px;
  color: #999;
}


.flash-message {
  position: absolute;
  top: 0;
  z-index: 100;
  left: 0;
  width: 100%;
}

.flash-message-content {
  background:$blue;
  color: #FFF;
  padding: 40px;
  position: fixed;
  bottom: 0;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-top:1px solid #FFF;

  .close {
    font-size: 20px;
    color: #FFF;
    position:absolute;
    right:20px;
    top:20px;
    display:none;
  }
}
