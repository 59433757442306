#line-2-blocks {
  .deploiement-approches-item{
    opacity: 1;
    transform: translateY(0);
    border:1px solid #EEE;

    .deploiement-approches-item-title{
      color:$blue;
    }
  }
}

#deploiement-approches-list{
  padding-left: 0;

  @include media-width($medium){
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
}

.deploiement-approches-item{
  padding: (40px / 640px) * 100%;
  margin-bottom: (40px / 640px) * 100% !important;
  background-color: #fff;

  @include media-width($medium){
    float: left;
    width: $col-6;
    // padding: $gutter;
    padding: 40px; // flex parent doesn't alowed margin/padding with percentage (at least FF)
    margin-right: $gutter;
    // margin-right: 40px;
    // margin-bottom: $gutter !important;
    margin-bottom: 40px !important;

    &:nth-child(even){
      margin-right: 0;
    }

    &:nth-last-child(-n + 2){
      margin-bottom: 0 !important;
    }
  }

  &::before{
    content: none !important;
  }

  .in-view-active &{
    transform: translateY(70px);
    opacity: 0;
  }

  &.in-view{
    opacity: 1;
    transform: translateY(0);
    transition: transform .8s $easeOutExpo, opacity 0s;

    &:nth-child(even){
      transition-delay: .1s;
    }
  }
}

.deploiement-approches-item-picto{
  margin-bottom: 50px;
  text-align: center;
  line-height: 0;
}

.deploiement-approches-item-picto svg{
  height: auto;

  .deploiement-approches-item:nth-child(1) &{
    width: (420px / (560px - 40px * 2)) * 100%;
  }

  .deploiement-approches-item:nth-child(2) &{
    width: (330px / (560px - 40px * 2)) * 100%;
  }

  .deploiement-approches-item:nth-child(3) &{
    width: (330px / (560px - 40px * 2)) * 100%;
  }

  .deploiement-approches-item:nth-child(4) &{
    width: (330px / (560px - 40px * 2)) * 100%;
  }
}

.deploiement-approches-item-title{
  padding-bottom: 15px;
  font-family: $dinbold;
  color: #000;

  @include media-width($medium){
    padding-bottom: 25px;
  }
}

.deploiement-approches-item-body{
  font-size: 16px;
  line-height: 25px;
}

