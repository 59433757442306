#worldwide-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-width($small){
    display: block;
    min-height: 0;
  }
}

#worldwide-intro{
  text-align: center;
  background-color: #000;

  @include media-width($small){
    position: absolute;
    top: 50%;
    left: 50%;
    width: 285px;
    height: 330px;
    margin-top: -165px;
    margin-left: -142px;
    line-height: 330px;
    z-index: 1;
    background-color: transparent;
  }
}

#worldwide-intro-inner{
  padding: 25px 0;

  @include media-width($small){
    display: inline-block;
    padding: 0;
    margin-top: -10px;
    vertical-align: middle;
    line-height: 0;
  }
}

#worldwide-logo{
  display: inline-block;
  width: 135px;
  height: 30px;
  margin-bottom: 20px;

  @include media-width($small){
  }
}

#worldwide-hexagon{
  display: none;

  @include media-width($small){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

#worldwide-title{
  padding: 0 30px;
  margin: 0 (25px / 720px) * 100%;
  color: #fff;
  font-family: $droid;
  font-style: italic;
  font-size: 18px;
  line-height: 25px;

  @include media-width($small){
    margin: 0;
  }
}

#worldwide-countries{
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  @include media-width($small){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.worldwide-country{
  padding-bottom: 80px;
  text-align: center;
  color: #fff;
  background-position: center 40%;
  background-size: cover;
  background-repeat: no-repeat;
  flex-grow: 1;

  &:first-child{
    left: 0;
  }

  @include media-width($small){
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    padding-bottom: 0;
    opacity: .6;
    transition: opacity .6s;

    &:hover{
      opacity: 1;
    }

    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      width: 2px;
      height: 100%;
      background-color: #000;
    }

    &:first-child::after{
      right: -1px;
      left: auto;
    }
  }
}

#worldwide-fr{
  background-image: url("../images/worldwide-fr.jpg");
}

#worldwide-de{
  background-image: url("../images/worldwide-de.jpg");
}

.worldwide-country-title{
  padding-top: 25px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: $dinbold;
  letter-spacing: .1em;

  @include media-width($small){
    padding-top: 65px;
    font-size: 30px;
    line-height: 30px;
  }
}

.worldwide-country-link{
  position: relative;
  display: inline-block;
  padding: 0 25px;
  margin-top: 10px;
  color: #fff;
  font-family: $dinmedium;
  font-size: 10px;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: .1em;
  border: 2px solid #fff;
  z-index: 1;

  @include media-width($small){
    margin-top: 30px;
    font-size: 13px;
    line-height: 46px;
  }

  &:hover{
    color: #000;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0;
    transition: transform .8s $easeOutExpo, opacity 0s .6s;
  }

  &:hover::before{
    transform-origin: left;
    transform: scaleX(1);
    transition: transform .6s $easeOutExpo;
    opacity: 1;
  }
}

