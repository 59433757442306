.hexagon-header{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 280px;
  height: 324px;
  padding-top: 70px;
  margin-bottom: -80px;
  margin-left: -140px;
  z-index: 3;
  color: #fff;
  text-align: center;

  @include media-width($medium){
    width: 380px;
    height: 440px;
    padding-top: 100px;
    margin-bottom: -110px;
    margin-left: -190px;
  }
}

.hexagon-header-shape{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  fill: #161616;
  // fill: #000;
}

.hexagon-header-title{
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;

  @include media-width($medium){
    font-size: 32px;
    line-height: 32px;
  }
}

.hexagon-header-title span{
  display: inline-block;
  width: 100%;
  font-size: 34px;
  line-height: 34px;
  font-family: $dinblack;
  letter-spacing: .125em;

  @include media-width($medium){
    font-size: 48px;
    line-height: 48px;
  }
}

.hexagon-header-body{
  margin: 15px 25px 0;
  font-size: 14px;
  line-height: 20px;
  font-family: $din;
  color: #fff;
  opacity: .6;

  @include media-width($medium){
    margin: 25px 40px 0;
    font-size: 15px;
    line-height: 25px;
  }
}

.hexagon-header-arrow{
  position: absolute;
  left: 50%;
  bottom: 35px;

  @include media-width($medium){
    bottom: 55px;
  }

  &::before,
  &::after{
    content: "";
  }

  &::before{
    display: block;
    width: 3px;
    height: 28px;
    background-color: $blue;

    @include media-width($medium){
      height: 32px;
    }
  }

  &::after{
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 12px;
    height: 12px;
    border-right: 3px solid $blue;
    border-bottom: 3px solid $blue;
    transform: rotate(45deg) translateX(-52%);
    // backface-visibility: hidden;
  }
}
