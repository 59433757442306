#changement-content-block{
  padding: 0;
}

#changement-content-block .level-2-content-block-inner{
  width: 100%;
  max-width: none;
}

#changement-etapes-wrapper{
  padding-top: 30px;
  color: #fff;
  text-align: center;
  @include media-width($medium){
    height: 560px;
    padding-top: 80px;
  }
}

#changement-etapes-inner{
  margin: 0 $margin;

  @include media-width($medium){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
    margin: 0;
  }
}

#changement-etapes-wrapper .level-2-content-title-1{
  margin-bottom: 50px;
  color: #fff;
  text-align: left;

  @include media-width($medium){
    margin-bottom: 70px;
  }
}

#changement-etapes{
  display: flex;
  padding-left: 0;
  padding-bottom: 40px;
  font-size: 0; // fix inline block spacing

  @include media-width($medium){
    display: block;
    padding-bottom: 0;
  }
}

.changement-etape{
  position: relative;
  display: inline-block;
  width: $col-4;
  min-height: 235px;
  padding-bottom: 20px;
  margin-right: $gutter - .1%; // - .1% fix cause 100% doesn't fit
  margin-bottom: 0;
  vertical-align: top;
  z-index: 1; // fix child z index -1
  // background-color: $blue;

  @include media-width($medium){
    height: 290px;
    min-height: 0;
    padding-bottom: 0;
  }

  &:first-child{
    margin-left: 0;
  }

  &:last-child{
    margin-right: 0;
  }

  &::before{
    content: "" !important;
    position: absolute !important;
    top: 0 !important;
    right: 0;
    bottom: 0;
    left: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 0 !important;
    z-index: -1;
    background-color: $blue !important;

    .in-view-active &{
      transform-origin: left;
      transform: scaleX(0);
    }

    .in-view &{
      transition: transform .7s $easeOutExpo;
      transform: scaleX(1);
    }
  }

  &:nth-child(2)::before{
    transition-delay: .2s;
  }

  &:nth-child(3)::before{
    transition-delay: .4s;
  }

  @include media-width($max: $medium - 1){
    &.selected::before{
      bottom: -40px;
    }
  }

  &::after{
    position: absolute;
    bottom: -54px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -16px;
    border-style: solid;
    border-width: 15px 16px 0 16px;
    border-color: $blue transparent transparent transparent;

    @include media-width($medium){
      content: "";
      bottom: -80px;
      border-width: 0 16px 15px 16px;
      border-color: transparent transparent #fff transparent;
    }
  }

  &.selected::after{
    content: "";
  }
}

.changement-etape-num{
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 20px 0 10px;
  font-family: $dinbold;
  color: $blue;
  background-color: #349ccc;
  border-radius: 50%;
  font-size: 15px;
  line-height: 25px;

  @include media-width($medium){
    position: absolute;
    top: 21px;
    left: 21px;
    width: 31px;
    height: 31px;
    margin: 0;
    font-size: 18px;
    line-height: 31px;
  }

  .in-view-active &{
    transform: scale(0, 0);
  }

  .in-view &{
    transition: transform .6s $easeOutExpo;
    transform: scale(1, 1);
  }

  .changement-etape:nth-child(2) &{
    transition-delay: .3s;
  }

  .changement-etape:nth-child(3) &{
    transition-delay: .5s;
  }
}

.changement-etape-picto{
  height: 90px;
  line-height: 90px;

  @include media-width($medium){
    height: 120px;
    margin-top: 50px;
    line-height: 120px;
  }

  .in-view-active &{
    transform: translateY(50px);
    opacity: 0;
  }

  .in-view &{
    transition: transform .6s $easeOutExpo, opacity .3s;
    transform: translateY(0);
    opacity: 1;
    transition-delay: .3s;
  }

  .changement-etape:nth-child(2) &{
    transition-delay: .4s;
  }

  .changement-etape:nth-child(3) &{
    transition-delay: .6s;
  }

  svg{
    display: inline-block;
    vertical-align: middle;
  }

  .changement-etape:nth-child(1) & svg{
    width: 34px;
    height: 34px;

    @include media-width($medium){
      width: 48px;
      height: 48px;
    }
  }

  .changement-etape:nth-child(2) & svg{
    width: 34px;
    height: 34px;

    @include media-width($medium){
      width: 48px;
      height: 48px;
    }
  }

  .changement-etape:nth-child(3) & svg{
    width: 34px;
    height: 34px;

    @include media-width($medium){
      width: 48px;
      height: 48px;
    }
  }

  svg path{
    fill: #fff;
  }
}

.changement-etape-label{
  padding: 0 (50px / 350px) * 100%;
  font-family: $dinbold;
  font-size: 12px;
  line-height: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-width($medium){
    font-size: 20px;
    line-height: 30px;
  }

  .in-view-active &{
    transform: translateY(50px);
    opacity: 0;
  }

  .in-view &{
    transition: transform .6s $easeOutExpo, opacity .3s;
    transform: translateY(0);
    opacity: 1;
    transition-delay: .5s;
  }

  .changement-etape:nth-child(2) &{
    transition-delay: .6s;
  }

  .changement-etape:nth-child(3) &{
    transition-delay: .7s;
  }
}

#changement-etapes-details-wrapper{
  text-align: center;
  background:#FFF;
}

#changement-etapes-details{
  padding-left: 0;
  margin: 0 $margin;
  text-align: left;

  @include media-width($medium){
    display: inline-block; // flexbox fallback
    display: inline-flex;
    width: $grid;
    max-width: $max-grid;
  }
}

#changement-etapes-details .changement-etape-detail{
  display: none;
  padding: 50px $gutter 60px;

  &.selected{
    display: block;
  }

  @include media-width($medium){
    display: block;
    float: left;
    width: $col-4 + $gutter;

    &:first-child,
    &:last-child{
      width: $col-4 + $half-gutter;
    }

    &:first-child{
      padding-left: 0;
      border-right: 1px solid rgba(0,0,0,.1);
    }

    &:last-child{
      padding-right: 0;
      border-left: 1px solid rgba(0,0,0,.1);
    }
  }

  &::before{
    content: none;
  }
}

