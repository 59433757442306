.video-popin-overlay{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 100;
  visibility: hidden;
  backface-visibility: hidden;
}

.video-popin{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 94%;
  max-width: 800px;
  transform: translate(-50%,-50%);
  background-color: #000;
  z-index: 101;
  visibility: hidden;

  @include media-width($large){
    max-width: 1000px;
  }

  @include media-width($xlarge){
    max-width: 1100px;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.video-popin iframe{
  width: 100%;
  height: 100%;
  border: none;
}

.video-popin-inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
