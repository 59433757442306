#deploiement-facteurs{
  border-top: 5px solid $blue;
}

#deploiement-facteurs-title{
  padding-top: 35px;
  font-family: $dinbold;
  font-size: 18px;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  color: #000;

  @include media-width($medium){
    padding-top: 45px;
    font-size: 24px;
    letter-spacing: .2em;
  }
}

#deploiement-facteurs-subtitle{
  font-family: $dinbold;
  font-size: 13px;
  letter-spacing: .2em;
  text-transform: uppercase;
  text-align: center;
  color: $blue;

  @include media-width($medium){
    font-size: 16px;
    letter-spacing: .2em;
  }
}

#deploiement-facteurs-list{
  padding-top: 9px;
  padding-left: 0;
  margin-top: 20px;

  @include media-width($medium){
    display: flex;
    overflow: hidden;
  }
}

.deploiement-facteurs-item{
  position: relative;
  padding: 20px ((40px / 720px) * 100%);
  border: 1px solid #e6e6e6;

  &:first-child{
    margin-left: 0;
  }

  @include media-width($medium){
    float: left; // flexbox fallback
    width: $col-3;
    padding: 30px ($col / 2);
    margin-bottom: 0 !important;
    margin-left: $gutter;
  }

  @include media-width($xlarge){
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &::before,
  &::after{
    top: 0 !important;
    left: 50% !important;
    width: 0;
    height: 0;
    margin-left: -10px;
    margin-top: -9px;
    border-style: solid;
    border-width: 0 10.5px 9px 10.5px;
    border-color: transparent transparent #e6e6e6 transparent;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  &::after{
    content: "";
    position: absolute;
    margin-top: -8px;
    border-color: transparent transparent #fff transparent;
  }

  .in-view-active &{
    transform: translateY(70px);
    opacity: 0;
  }

  .in-view &{
    opacity: 1;
    transform: translateY(0);
    transition: transform .8s $easeOutExpo, opacity 0s;

    &:nth-child(2){
      transition-delay: .1s;
    }

    &:nth-child(3){
      transition-delay: .2s;
    }

    &:nth-child(4){
      transition-delay: .3s;
    }
  }
}

.deploiement-facteurs-item-picto{
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  text-align: center;
}

.deploiement-facteurs-item-picto svg{
  display: inline-block;
  vertical-align: middle;

  .deploiement-facteurs-item:nth-child(1) &{
    width: 52px;
    height: 60px;
  }

  .deploiement-facteurs-item:nth-child(2) &{
    width: 56px;
    height: 56px;
  }

  .deploiement-facteurs-item:nth-child(3) &{
    width: 142px;
    height: 61px;
  }

  .deploiement-facteurs-item:nth-child(4) &{
    width: 61px;
    height: 57px;
  }
}

.deploiement-facteurs-item-picto path{
  stroke: $blue;
  fill: #fff;
}

.deploiement-facteurs-item-title{
  padding-bottom: 20px;
  font-family: $dinbold;
  color: #000;
}

.deploiement-facteurs-item-body{
  font-size: 16px;
  line-height: 25px;
}

