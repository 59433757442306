.level-2-page-header{
  overflow: hidden;

  @include media-width($medium){
    text-align: center;
  }
}

.level-2-page-header .page-header-img-wrapper{
  height: 265px;

  @include media-width($medium){
    height: 530px;
    margin-top: 0;
  }
}

.level-2-nav{
  position: relative;
  width: 100%;
  margin-top: -40px;

  @include media-width($medium){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
    margin-top: -140px;
    vertical-align: top;
    text-align: left;
  }
}

.level-2-nav-parent-page{
  position: relative;
  display: block;
  width: (450px / 720px) * 100%;
  height: 40px;
  padding-left: (60px / 720px) * 100%;
  z-index: 1;
  line-height: 40px;
  text-transform: uppercase;
  font-family: $dinbold;
  font-size: 10px;
  letter-spacing: .05em;
  color: rgba(0,0,0,.7);

  @include media-width($medium){
    width: $col-2 + $gutter / 2;
    height: 50px;
    padding-left: 0;
    line-height: 50px;
    font-size: 12px;
  }

  @include media-width($large){
    font-size: 14px;
  }

  &:hover{
    color: $blue;
  }
}

.level-2-nav-parent-page-bg{
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #2ba8e2;

  @include media-width($medium){
    width: 50vw;
    right: -5vw;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #161616;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .7s $easeOutExpo;

    .level-2-nav-parent-page:hover &{
      transform: scaleX(1);
      transform-origin: left;
      transition-duration: .5s;
    }
  }
}

.level-2-nav-parent-page-title{
  display: inline-block;

  @include media-width($medium){
    width: 125%;
  }

  .level-2-nav-parent-page:hover &{
    animation: level-2-nav-parent-page-title-roll-over .5s $easeOutExpo;
  }
}

@keyframes level-2-nav-parent-page-title-roll-over{
  from{
    transform: translateY(50px);
  }

  to{
    transform: translateY(0);
  }
}

.level-2-nav-current-page{
  position: relative;
  padding: 0 (60px / 720px) * 100%;

  @include media-width($medium){
    float: left;
    width: $col-4;
    height: 150px;
    padding-right: (30px / 720px) * 100%;
    padding-left: 0;
    vertical-align: top;
    line-height: 150px;
  }

  @include media-width($large){
    padding-right: (60px / 720px) * 100%;
  }
}

.level-2-nav-current-page-bg{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: $blue;
  transform-origin: left top;

  @include media-width($medium){
    width: 50vw;
  }
}

.level-2-nav-current-page-title{
  position: relative;
  padding: 25px 0 30px;
  line-height: 25px;
  font-size: 23px;
  text-transform: uppercase;
  color: #fff;
  font-family: $dinblack;
  letter-spacing: .075em;

  @include media-width($medium){
    display: inline-block;
    padding: 0;
    margin-top: -7px;
    vertical-align: middle;
    font-size: 30px;
    line-height: 36px;
  }

  @include media-width($large){
    font-size: 32px;
    line-height: 40px;
  }
}

.level-2-nav-subpages-wrapper{
  position: relative;
  padding-bottom: 8px;
  line-height: 0;
  white-space: nowrap;

  @include media-width($max: $medium - 1){
    overflow-x: auto; // can't reset it with IE 10, so applied until this breakpoint
  }

  @include media-width($medium){
    display: inline-block;
    width: $col-8 + $gutter - .1%;
    margin-top: 90px;
    vertical-align: top;
    white-space: normal;
  }

  // &::before{
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%; // fallback vw
  //   height: 100%; // fallback calc
  //   height: calc(100% - 8px);
  //   background-color: #000;

  //   @include media-width($medium){
  //     width: 100vw;
  //   }
  // }
}

.level-2-nav-subpages-line{
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 8px;
  opacity: 0;
  background-color: $blue;
  transform-origin: left top;

  .js &{
    display: block;
  }
}

.level-2-nav-subpages-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; // fallback vw
  height: 100%; // fallback calc
  height: calc(100% - 8px);
  background-color: #000;
  transform-origin: left top;

  @include media-width($medium){
    width: 100vw;
  }
}

.level-2-nav-subpages{
  @include media-width($medium){
    display: flex;
  }
}

.level-2-nav-subpage{
  position: relative;
  display: inline-block;
  width: (200px / 720px) * 100%;
  max-width: 160px;
  background-color: #000;
  white-space: normal;

  .no-flexbox &{
    margin-right: -4px;
  }

  @include media-width($medium){
    width: 20%; // flex fallback
    flex: 1 0 0;
    max-width: none;
    background-color: transparent;
  }

  .no-js &.selected::after{
    content: "";
    position: absolute;
    top: 100%;
    right: 3px;
    bottom: -8px;
    left: 1px;
    background-color: $blue;
  }
}

.level-2-nav-subpage-btn{
  position: relative;
  display: block;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-family: $din;

  &:hover{
    color: $blue;
    transition: color .3s;
  }

  @include media-width($medium){
    height: 120px;

    line-height: 120px;
  }

  .level-2-nav-subpage.selected &{
    color: $blue;
    font-family: $dinbold;
  }

  &::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    height: (90px / 120px) * 100%;
    background-color: rgba(255,255,255,.3);

    .level-2-nav-subpage:first-child &{
      content: none;
    }
  }
}

.level-2-nav-subpage-btn-label{
  display: inline-block;
  padding: 0 (30px / 200px) * 100%;
  margin-top: 10px;
  vertical-align: top;
  line-height: 13px;
  font-size: 11px;

  @include media-width($medium){
    margin-top: 30px;
    font-size: 15px;
    line-height: 18px;
  }
}

.level-2-page-content{
  padding-top: 120px + 8px;
  margin-top: -120px - 8px;
}

.level-2-content-block{
  padding: 50px 0;
  text-align: center;
  color: #666;

  @media screen and (min-width:$small) {
    padding: 50px 0 40px 0;
  }

  @media screen and (min-width:$small) and (min-height:800px) {
    padding: 70px 0 55px 0;
  }

  @media screen and (min-width:$small) and (min-height:900px) {
    padding: 80px 0 65px 0;
  }

  &.grey{
    background-color: #e6e6e6;
  }

  &.background-top{
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  &.background-cover{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.level-2-content-block-inner{
  display: inline-block;
  width: $grid;
  max-width: $max-grid;
  text-align: left;

  & > *:last-child{
    margin-bottom: 0;
  }

  p, a, ul {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  h2 * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
  }

  .block-btn-label {
    font-size:15px;
  }
}

.level-2-content-block p{
  @extend .content-paragraph;
}

.level-2-content-block img{
  @extend .content-image;
}

.level-2-content-block p a, .level-2-content-block a{
  @extend .content-link;
}

.level-2-content-block ul{
  @extend .content-list;
}

.level-2-content-block li{
  @extend .content-list-item;
}

.level-2-content-block .block-btn{
  width: 100%;
  max-width: 320px;
  height: 70px;
  padding: 0 (60px / 720px) * 100%;
  margin-top: 20px;

  @include media-width($small){
    padding: 0 40px;
  }
}

.level-2-content-title-1{
  margin-bottom: 25px;
  font-size: 23px;
  line-height: 30px;
  font-family: $droid;
  font-style: italic;
  color: #000;

  @include media-width($small){
    width: $col-8;
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 40px;
  }
}

.level-2-content-title-2{
  margin-bottom: 25px;
  color: $blue;
  font-size: 15px;
  line-height: 23px;
  font-family: $dinmedium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-width($small){
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 30px;
  }
}

.level-2-content-title-3{
  margin-bottom: 20px;
  color: #666;
  font-family: $dinmedium;

  @include media-width($small){
    margin-bottom: 30px;
  }
}

.level-2-container-col-1{
  margin-bottom: 30px;

  @include media-width($small){
    margin-bottom: 50px;
  }
}

.level-2-container-col-2{
  margin-bottom: 50px;

  & > *:last-child{
    margin-top: 30px;
  }

  @include media-width($small){
    margin-bottom: 70px;
  }

  @include media-width($medium){
    overflow: hidden;

    & > *,
    & > img{
      display: block;
      width: $col-6;
      padding-right: $col;
      float: left;

      &:first-child{
        margin-right: $gutter;
      }

      &:last-child{
        margin-top: 0;
      }
    }

    & > img{
      padding-right: 0;
    }

    &.level-2-container-layout-2-1{
      & > *,
      & > img{
        &:first-child{
          width: $col-8;
        }

        &:last-child{
          width: $col-4;
          padding-right: $half-gutter;
        }
      }
    }

    &.level-2-container-layout-1-2{
      & > *,
      & > img{
        &:first-child{
          width: $col-4;
        }

        &:last-child{
          width: $col-8;
          padding-right: $half-gutter;
        }
      }
    }
  }
}

.level-2-container-col-3{
  @include media-width($medium){
    overflow: hidden;

    & > *{
      width: $col-4;
      padding-right: $half-gutter;
      margin-left: $gutter;
      float: left;

      &:first-child{
        margin-left: 0;
      }
    }

    & > li{
      margin-bottom: 0;
    }
  }
}

.level-2-container-large-text{
  width: $col-9;
  margin-bottom: 60px;
}

.level-2-page .hide-link{
  display: none;
}
