#footer{
  padding-top: 40px;
  background-color: #f2f2f2;
  text-align: center;

  @include media-width($small){
    padding-top: 70px;
  }
}

@media print{
  #footer{
    display: none;
  }
}

#footer-sitemap{
  display: inline-block;
  width: 100% - 8.3% * 2; // margin = 8.3%
  max-width: 1160px;
  text-align: left;

  @include media-width($small){
    width: 100% - 4% * 2; // margin = 4%
    padding-bottom: 55px;
  }

  @include media-width($medium){
    width: 82.9%; // 1160 / 1400
  }
}

.footer-sitemap-col{
  display: inline-block;
  width: 50%;
  margin-left: -4px; // fix inline-block spacing
  padding-bottom: 25px;
  vertical-align: top;

  @include media-width($small){
    width: (100% - 3 * 3.4%) / 4; // 4 columns
    padding-bottom: 0;
    margin-right: 3.4%;
  }

  &:last-child{
    margin-right: 0;
  }
}

.footer-sitemap-page-title{
  display: block;
  margin-bottom: 10px;
  font-family: $droid;
  font-style: italic;
  color: #000;
  font-size: 15px;
  line-height: 15px;

  @include media-width($small){
    margin-bottom: 20px;
  }

  @include media-width($large){
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 20px;
  }
}

.footer-sitemap-page-title span{
  position: relative;
  display: inline-block;

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $blue;
    transform: scaleX(0);
    transition: transform .7s $easeOutExpo;
    transform-origin: right;
  }
}

.footer-sitemap-page-title:hover span::after{
  transform: scaleX(1);
  transform-origin: left;
  transition: transform .5s $easeOutExpo;
}

#footer-sitemap-contact-page-title{
  padding-top: 8px;

  @include media-width($small){
    padding-bottom: 0;
  }
}

.footer-sitemap-subpage{
  line-height: 30px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: none;
}

.footer-sitemap-subpage-link{
  display: inline-block;
  color: #999;
  font-size: 11px;
  line-height: 15px;
  transition: color .4s;

  @include media-width($medium){
    font-size: 13px;
    line-height: 18px;
  }

  @include media-width($large){
    font-size: 15px;
    line-height: 22px;
  }

  &:hover{
    color: #000;
  }
}

#footer-infos{
  font-size: 11px;
  line-height: 30px;

  @include media-width($small){
    border-top: 1px solid #dadde3;
  }

  @include media-width($medium){
    height: 90px;
    font-size: 13px;
  }

  @include media-width($large){
    font-size: 15px;
  }
}

#footer-infos-inner{
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 1160px;
  height: 100%;
  text-align: left;

  @include media-width($small){
    // display: inline-flex;
    flex-flow: row wrap;
  }

  @include media-width($medium){
    display: inline-block;
    width: 82.9%; // 1160 / 1400 (mockup layout)
  }
}

#footer-copyright{
  height: 80px;
  padding-top: 22px;
  padding-left: 8.3%;
  vertical-align: top;
  font-size: 10px;
  color: #ccc;
  border-top: 1px solid #dadde3;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: none;

  @include media-width($small){
    padding-top: 28px;
    padding-right: 3.4%;
    padding-left: 4%;
    flex: 0 0 100%;
    order: 2;
  }

  @include media-width($medium){
    display: inline-block;
    width: 22.4%;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
    border-top: 0;
    border-right: 1px solid #dadde3;
  }

  @include media-width($large){
    font-size: 14px;
  }
}

#footer-contact{
  height: 80px;
  padding-top: 22px;
  padding-left: 8.3%;
  vertical-align: top;
  color: #000;
  border-top: 1px solid #dadde3;

  @include media-width($small){
    display: inline-block;
    padding-right: 3.4%;
    padding-left: 4%;
    margin-left: -4px;
    border-right: 1px solid #dadde3;
    border-top: 0;
  }

  @include media-width($medium){
    height: 100%;
    padding-top: 28px;
    padding-left: 3.4%;
  }
}

#footer-contact-email,
#footer-contact-phone{
  display: inline-block;
}

#footer-contact-email-label,
#footer-contact-phone-label{
  display: inline-block;
}

#footer-contact-phone{
  margin-left: -4px;
  padding-left: 20px;

  @include media-width($large){
    padding-left: 30px;
  }
}

#footer-contact a{
  color: #000;
}

#footer-contact-email-link{
  position: relative;

  &::before,
  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  &::before{
    transform-origin: right;
    transform: scaleX(0);
    background-color: #000;
    transition: transform .5s $easeOutExpo;
  }

  &:hover::before{
    transform-origin: left;
    transform: scaleX(1);
    transition: transform .5s $easeInOutExpo;
  }

  &::after{
    transform-origin: left;
    background-color: $blue;
    transition: transform .5s $easeInOutExpo;
  }

  &:hover::after{
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s $easeOutExpo;
  }
}

#footer-social{
  height: 80px;
  padding-top: 18px;
  padding-left: 8.3%;
  vertical-align: top;
  border-top: 1px solid #dadde3;

  @include media-width($small){
    display: inline-block;
    padding-left: 3.4%;
    margin-left: -4px;
    border-top: 0;
  }

  @include media-width($medium){
    height: auto;
    padding-top: 24px;
  }
}

#footer-social-title{
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
  margin-top: 5px;
  color: #999;

  @include media-width($small){
    margin-top: 5px;
  }

  @include media-width($medium){
    padding-right: 15px;
  }

  @include media-width($large){
    padding-right: 20px;
  }

  @include media-width($xlarge){
    padding-right: 30px;
  }
}

.footer-social-button{
  display: inline-block;
  margin-right: 24px;
  margin-left: -4px;
  line-height: 40px;

  @include media-width($medium){
    margin-right: 10px;
  }

  @include media-width($large){
    margin-right: 20px;
  }

  @include media-width($xlarge){
    margin-right: 40px;
  }

  &:last-child{
    margin-right: 0;
  }
}

.footer-social-button-picto{
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;

  @include media-width($large){
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  &::before{
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0,0);

    .footer-social-button:hover &{
      opacity: 1;
      transform: scale(1,1);
      transition: transform .5s $easeOutExpo, opacity .2s;
    }
  }
}

.footer-social-button-picto svg{
  position: absolute;
  display: block;

  .footer-social-button:hover &{
    animation: footer-social-button-picto-roll-over .7s $easeOutExpo;
  }
}

@keyframes footer-social-button-picto-roll-over{
  from{
    transform: translateY(10px);
  }

  to{
    transform: translateY(0);
  }
}

#footer-social-facebook-picto{
  background-color: #3b5998;
}

#footer-social-facebook-picto svg{
  top: 5px;
  left: 7px;
  width: 5px;
  height: 10px;

  @include media-width($large){
    top: 10px;
    left: 15px;
    width: 10px;
    height: 20px;
  }

  .footer-social-button:hover & path{
    fill: #3b5998;
  }
}

#footer-social-linkedin-picto{
  background-color: #0065a0;
}

#footer-social-linkedin-picto svg{
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;

  @include media-width($large){
    top: 12px;
    left: 12px;
    width: 16px;
    height: 16px;
  }

  .footer-social-button:hover & path{
    fill: #0065a0;
  }
}

.footer-social-button-label{
  display: inline-block;
  padding-left: 10px;
  vertical-align: top;
  margin-left: -4px;
  color: #666;
  font-family: $din;

  @include media-width($medium){
    padding-left: 8px;
  }

  @include media-width($large){
    padding-left: 14px;
  }

  @include media-width($xlarge){
    padding-left: 20px;
  }
}

