#formation-ccl{
  background-color: #f2f2f2;
}

#formation-ccl-platforms{
  @include media-width($medium){
    display: flex;
    align-items: stretch;
  }
}

#formation-ccl-platform-partnership-1,
#formation-ccl-platform-partnership-2{
  display: flex;
  flex-direction: column;
  padding-right: 0;
}

.formation-ccl-partner{
  padding: 10px 0 30px;
  font-family: $dinbold;
  color: $blue;
  border-top: 5px solid $blue;

  @include media-width($max: $medium - 1){
    height: auto !important;
  }
}

#formation-ccl-platform-partnership-1 .level-2-container-col-2{
  margin-bottom: 0;
}

.formation-ccl-platform{
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  // flex-direction: column;
  padding-top: 30px;
  padding-right: 0;
  background-color: #fff;

  #formation-ccl-platform-partnership-1 &{

    @include media-width($medium){
      width: (360px / 760px) * 100%;

      &:first-child{
        margin-right: (40px / 760px) * 100%;
      }
    }
  }
}

.formation-ccl-platform-infos{
  padding: 0 (40px / 720px) * 100%;

  @include media-width($max: $medium - 1){
    height: auto !important;
  }

  @include media-width($medium){
    padding: 0 (40px / 360px) * 100%;
  }
}

.formation-ccl-platform-date{
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  font-family: $dinbold;
  font-size: 14px;
  color: #fff;
  background-color: $blue;
}

.formation-ccl-platform-title{
  font-size: inherit;
}

.formation-ccl-platform-img{
  display: block;
  align-self: flex-end;
}

