#case-content-block{
  padding: 0;
}

#case-content-block .level-2-content-block-inner{
  width: 100%;
  max-width: none;
}

.case-heading{
  position: relative;
  text-align: center;
}

.case-heading-inner{
  padding-top: 50px;
  margin: 0 $margin;
  text-align: left;
  overflow: inherit;

  @include media-width($medium){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
    padding-top: 80px;
  }
}

.case-title-block{
  position: relative;
  padding: (60px / 720px) * 100%;
  margin-bottom: 20px;
  color: #fff;
  z-index: 1;

  @include media-width($medium){
    padding: $gutter $col $gutter $gutter;
    margin-bottom: 0;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #209cd5;
    z-index: -1;
    transform-origin: right;

    .in-view-active &{
      transform: translateX(-100%) scaleX(0);
    }
  }

  &.in-view::before{
    transform: translateX(0) scaleX(1);
    transition: transform 1s $easeOutExpo; // animation fallback
    animation: case-title-block-anim-in 1s forwards $easeOutQuart;
  }
}

@keyframes case-title-block-anim-in{
  0%{
    transform: scaleX(2) translateX(-200%);
  }

  60%{
    transform: scaleX(2) translateX(0);
  }

  100%{
    transform: scaleX(1) translateX(0);
  }
}

.case-duration{
  padding-bottom: 15px;
  margin-bottom: -5px;
  opacity: .5;

  @include media-width($medium){
    margin-top: -10px;
  }

  .in-view-active &{
    transform: translateY(50px);
    opacity: 0;
  }

  .in-view &{
    transition: transform .6s $easeOutExpo, opacity .3s;
    transition-delay: .4s;
    transform: translateY(0);
    opacity: 1;
  }
}

.case-title{
  width: 100%;
  margin-bottom: 0;
  color: inherit;

  @include media-width($medium){
    padding-bottom: 55px;
  }

  .in-view-active &{
    transform: translateY(50px);
    opacity: 0;
  }

  .in-view &{
    transition: transform .7s $easeOutExpo, opacity .3s;
    transition-delay: .5s;
    transform: translateY(0);
    opacity: 1;
  }
}

.case-heading .case-info{
  padding-top: $gutter;
  @include media-width($medium){
    padding-bottom: 80px;
  }
}

.case-heading .case-info .level-2-content-title-2{
  @include media-width($medium){
    margin-top: -10px;
  }
}

.case-heading .case-info-1{
  @include media-width($medium){
    padding-right: $gutter;
    padding-left: $col;
  }
}

.case-heading .case-info-2{
  @include media-width($medium){
    padding-right: $col;
  }
}

.case-img-wrapper{
  position: relative;
  overflow: hidden;
  // background-color: #f2f2f2;

  @include media-width($medium){
    margin-top: -80px;
  }

  &::before{
    content: "";
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: (620px / 1400px) * 100%;
  }
}

.case-img-inner{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

.case-img{
  display: block; // no inline font spacing
}

.case-infos-2{
  padding: 20px 0 60px;
  text-align: center;

  @include media-width($medium){
    padding: 0;
  }
}

.case-infos-2-inner{
  text-align: left;

  @include media-width($medium){
    display: inline-block; // flex fallback
    display: inline-flex;
    width: $grid;
    max-width: $max-grid;
  }
}

.case-infos-2 .case-info{
  padding-top: 20px;
  margin: 0 $margin;

  @include media-width($medium){
    float: left;
    width: $col-4 + $gutter;
    padding: 50px $gutter 60px;
    margin: 0;

    &:first-child,
    &:last-child{
      width: $col-4 + $half-gutter;
    }

    &:first-child{
      padding-left: 0;
      border-right: 1px solid rgba(0,0,0,.1);
    }

    &:last-child{
      padding-right: 0;
      border-left: 1px solid rgba(0,0,0,.1);
    }
  }

  &::before{
    content: none;
  }
}

