.landing-list{
  padding-left: 0 !important;

  @include media-width($small){
    display: flex;
    flex-wrap: wrap;
  }
}

.landing-item{
  // margin-bottom: (80px / 720px) * 100% !important;
  margin-bottom: 80px !important; // FF doesn't allowed a flex child with percentage padding/margin
  box-shadow: 0 0 6px 2px rgba(0,0,0,.06);

  @include media-width($small){
    display: flex;
    flex-direction: column;
    float: left; // flexbox fallback
    width: $col-6;
    // margin-bottom: (70px / $max-grid) * 100% !important;
    margin-bottom: 40px !important;  // FF doesn't allowed a flex child with percentage padding/margin
    margin-left: $gutter;
  }

  @include media-width($large){
    margin-bottom: 70px !important;  // FF doesn't allowed a flex child with percentage padding/margin
  }

  @include media-width($max: $medium - 1){
    &:nth-child(2n + 1){
      clear: left;
      margin-left: 0;
    }
  }

  @include media-width($medium){
    width: $col-4;

    &:nth-child(3n + 1){
      clear: left;
      margin-left: 0;
    }
  }

  &::before{
    content: none !important;
  }
}

.landing-item-thumb-link{
  display: block;
  overflow: hidden;
}

.landing-item-thumb{
  display: block;
  width: 100%;
  height: auto;
  transition: transform 2s $easeOutExpo;

  .landing-item-thumb-link:hover &{
    transition: transform 1.4s $easeOutExpo;
    transform: scale(1.05, 1.05);
  }
}

.landing-item-title{
  // padding: (30px / 360px) * 100%;
  padding: 30px; // FF doesn't allowed a flex child with percentage padding/margin
  margin-top: -7px;
  margin-bottom: 0 !important;

  @include media-width($medium){
    // padding: (40px / 360px) * 100%;
    padding: 40px; // FF doesn't allowed a flex child with percentage padding/margin
    margin-top: -10px;
  }
}

.landing-item-title a{
  color: inherit;
  background:none;
}

.landing-item-btn{
  max-width: none !important;
  margin-top: auto !important;
  font-size: 15px;
}

