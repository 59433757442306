.article-item{
  margin-bottom: 70px;
  text-align: left;
  vertical-align: top;

  &:last-child{
    margin-bottom: 0;
  }

  @include media-width($small){
    display: inline-block;
    width: $col-6;
    margin-right: -4px; // fix inline block spacing
    margin-bottom: 100px;
    margin-left: $gutter;

    &:nth-child(odd){
      margin-left: 0;
    }

    &:nth-last-child(-n + 2){
      margin-bottom: 0;
    }
  }
}

.article-item-img-link{
  display: block;
  overflow: hidden;
}

.article-item-img{
  display: block;
  width: 100%;
  height: auto;
  transition: transform 2s $easeOutExpo;

  .article-item-img-link:hover &{
    transition: transform 1.4s $easeOutExpo;
    transform: scale(1.05, 1.05);
  }
}

.article-item-inner{
  position: relative;
  margin: -((60px / 560px) * 100%) ((40px / 560px) * 100%) 0;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0,0,0,.06);

  @include media-width($small){
    margin: -(($max-col / 560px) * 100%) ((50px / 560px) * 100%) 0;
  }
}

.article-item-body{
  padding: (($max-col / 560px) * 100%);
}

.article-item-category,
.article-item-date{
  display: inline-block;
  font-size: 11px;
  line-height: 11px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  @include media-width($small){
    font-size: 14px;
    line-height: 14px;
  }
}

.article-item-date{
  opacity: .5;
  margin-left: -4px;
  padding-left: 10px;
}

.article-item-title{
  padding-top: 20px;
  font-size: 25px;
  font-family: $droid;
  font-style: italic;
  color: $blue;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.article-item-title a{
  color: inherit;
}

.article-item-desc{
  display: none;


  @include media-width($small){
    display: block;
    padding-top: 35px;
    font-size: 15px;
    line-height: 25px;
  }
}

.article-item .block-btn{
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 (($max-col / 560px) * 100%);
  line-height: 60px;

  @include media-width($small){
    height: 90px;
    line-height: 90px;
  }
}


