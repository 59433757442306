// breakpoints
$xsmall: 480px;
$small: 768px;
$medium: 1024px;
$large: 1280px;
$xlarge: 1400px;
$xxlarge: 1600px;

// fonts
$din: 'DIN Next W01 Regular', Helvetica, Arial, sans-serif;
$dinlight: 'DIN Next W01 Light', Helvetica, Arial, sans-serif;
$dinmedium: 'DIN Next W01 Medium', Helvetica, Arial, sans-serif;
$dinbold: 'DIN Next W01 Bold', Helvetica, Arial, sans-serif;
$dinblack: 'DIN Next LT W04 Black', Helvetica, Arial, sans-serif;
$droid: 'DroidSerif', Georgia, serif;

// colors
$blue: #41c3ff;

// layout - grid
$base-width: 1400px; // mockup
$max-grid: 1160px;
$max-gutter: 40px;
$max-col: 60px;

$grid: ($max-grid / $base-width) * 100%;
$gutter: ($max-gutter / $max-grid) * 100%;
$half-gutter: $gutter / 2;
$col: ($max-col / $max-grid) * 100%;
$col-2: 2 * $col + $gutter;
$col-3: 3 * $col + 2 * $gutter;
$col-4: 4 * $col + 3 * $gutter;
$col-5: 5 * $col + 4 * $gutter;
$col-6: 6 * $col + 5 * $gutter;
$col-7: 7 * $col + 6 * $gutter;
$col-8: 8 * $col + 7 * $gutter;
$col-9: 9 * $col + 8 * $gutter;
$col-10: 10 * $col + 9 * $gutter;

// layout - mobile
$margin: (40px / 720px) * 100%;

// header
$nav-bar-height: 60px;
