@keyframes left-to-right{
  from{
    transform: translateX(-100%);
  }

  to{
    transform: translateX(0);
  }
}

@keyframes right-to-left{
  to{
    transform: translateX(100%);
  }
}
