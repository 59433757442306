.level-1-page-header .page-header-img-wrapper{
  height: 500px;

  @include media-width($medium){
    height: 650px;
  }
}

.level-1-page-item{
  position: relative;

  @include media-width($medium){
    text-align: center;
  }
}

.level-1-page-item-content{
  @include media-width($medium){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
    min-height: 580px;
    text-align: left;
  }
}

.level-1-page-item-content-inner{
  position: relative;
  max-width: 580px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 (40px / 720px) * 100%;

  @include media-width($medium){
    max-width: none;
    padding-top: 90px;
    padding-bottom: 0;
    margin-right: $col-7 + $gutter;
    margin-left: 0;

    .level-1-page-item:nth-child(even) &{
      margin-left: $col-7 + $gutter;
      margin-right: 0;
    }
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: (140px / 460px) * 100%;
    height: 16px;
    background-color: $blue;
  }
}

.level-1-page-item-title{
  width: 90%;
  padding-bottom: 25px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: .025em;
  font-family: $droid;
  font-style: italic;

  @include media-width($medium){
    width: (300px / 460px) * 100%;
    padding-bottom: 45px;
  }

  .in-view-active &{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity .3s, transform .7s $easeOutExpo;
  }

  .in-view &{
    opacity: 1;
    transform: translateY(0);
  }
}

.level-1-page-item-title a{
  color: inherit;
  font-family:$droid;
}

.level-1-page-item-intro{
  padding-bottom: 45px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  a {
    @extend .content-link;
  }

  @include media-width($medium){
    padding-bottom: 65px;
  }

  .in-view-active &{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity .3s, transform .7s $easeOutExpo;
    transition-delay: .1s;
  }

  .in-view &{
    opacity: 1;
    transform: translateY(0);
  }
}

.level-1-page-item-btn{
  width: (330px / 460px) * 100%;
  height: 60px;
  max-width: 320px;
  padding: 0 (38px / 460px) * 100%;
  line-height: 60px;

  @include media-width($medium){
    height: 80px;
    line-height: 80px;
  }

  .in-view-active &{
    opacity: 0;
    transform: translateY(100px);
    transition: opacity .3s, transform .7s $easeOutExpo;
    transition-delay: .2s;
  }

  .in-view &{
    opacity: 1;
    transform: translateY(0);
  }
}

.level-1-page-item-img{
  display: block;
  height:320px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include media-width($xsmall){
    height:400px;
  }

  @media screen and (min-width:600px) {
    height:450px;
  }

  @include media-width($small){
    height:500px;
  }

  @include media-width($medium){
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    .level-1-page-item:nth-child(even) &{
      right: auto;
      left: 0;
    }
  }
}


