#blog-page-header .page-header-img-wrapper{
  height: 500px;

  @include media-width($medium){
    height: 650px;
  }
}

#blog-page-header .page-header-img{
  background-image: url("../images/blog-header.jpg");
}

#blog-page-content{
  padding: 70px 0 100px;
}

#blog-articles-pagination{
  margin-top: 100px;
  text-align: center;
}

#blog-articles-pagination > a,
.blog-articles-pagination-page{
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: -4px;
  vertical-align: top;
  line-height: 50px;
  border: 1px solid #e5e5e5;
  transition: border-color .3s;

  &:hover{
    border: 1px solid $blue;
  }
}

#blog-articles-pagination-pages{
  display: inline-block;
  padding: 0 50px;
}

.blog-articles-pagination-page{
  display: inline-block;
  margin-left: 20px;

  &:first-child{
    margin-left: 0;
  }

  &.selected{
    background-color: $blue;
    border-color: $blue;

    a{
      font-family: $dinbold;
      color: #fff !important;
    }
  }
}

.blog-articles-pagination-page a{
  display: block;
  color: #999;

  .blog-articles-pagination-page.selected &{
    color: #fff !important;
  }
}

#blog-articles-pagination-first,
#blog-articles-pagination-last,
#blog-articles-pagination-prev,
#blog-articles-pagination-next{
  position: relative;

  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-left: -2px;
    margin-top: -4px;
    transform: rotate(45deg);
    border-left: 2px solid #999;
    border-bottom: 2px solid #999;
    backface-visibility: hidden;
  }
}

#blog-articles-pagination-prev{
  margin-left: 20px;
}

#blog-articles-pagination-next{
  &::before{
    margin-left: -5px;
  }
}

#blog-articles-pagination-next,
#blog-articles-pagination-last{
  &::before{
    transform: rotate(-135deg);
  }
}

#blog-articles-pagination-first{
  &::before{
    margin-left: -5px;
  }
}

#blog-articles-pagination-first,
#blog-articles-pagination-last{
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-left: 1px;
    margin-top: -4px;
    transform: rotate(45deg);
    border-left: 2px solid #999;
    border-bottom: 2px solid #999;
    backface-visibility: hidden;
  }
}

#blog-articles-pagination-last{
  margin-left: 20px;

  &::before{
    margin-left: -3px;
  }

  &::after{
    margin-left: -9px;
    transform: rotate(-135deg);
  }
}

