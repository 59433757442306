#home-page{
  overflow: hidden; // IE 10 fix: numbers block overflow
}

#home-page-header{
  z-index: 1;

  @include media-width($medium){
    &::after{
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      height: 225px;
      margin-top: -225px;
      background-color: #161616;
      opacity: 1;
      background-image: none;
    }
  }
}

#home-page-header .page-header-img-wrapper{
  height: 80vh;

  @include media-width($small){
    height: 120vh; // calc fallback
    height: calc(100vh + 160px);
    z-index: 3;
  }

  &::after{
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 200px;
    content: "";
    background: linear-gradient(to top, rgba(22,22,22,1) 0%,rgba(22,22,22,0) 100%);
  }
}

#home-page-header .page-header-img{
  background-image: url("../images/home-header-mobile.jpg");

  @include media-width($small){
    background-image: url("../images/home-header.jpg");
    background-position:left 23% center;
  }
}

#home-video-btn{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  .cssfilters &{
    filter: drop-shadow(0 20px 6px rgba(0,0,0,.4)); // apply the filter to the element (fix iOS safari)
  }

  @include media-width($small){
    top: 40%;
    bottom: auto;
    right: 15%;
    left: auto;
    margin-top: -190px;
    transform: none;
  }
}

#home-video-btn-hexagon{
  position: relative;
  display: block;
  width: 235px + 40px;
  height: 270px + 40px;
  padding: 20px; // space for svg drop shadow
  backface-visibility: hidden; // fix antialisasing

  @include media-width($small){
    width: 380px + 40px;
    height: 436px + 40px;
  }
}

#home-video-btn-hexagon-color-wrapper{
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  overflow: hidden;
  transform: translateY(62%);

  #home-video-btn.visible &{
    transition: transform .6s $easeOutExpo;
  }

  #home-video-btn.visible:hover &{
    transform: translateY(58%);
  }
}

#home-video-btn-hexagon-color{
  width: 100%;
  height: 100%;
  display: block;
  fill: $blue;
  transform: translateY(-62%);
  backface-visibility: hidden; // fix antialisasing

  #home-video-btn.visible &{
    transition: transform .6s $easeOutExpo;
  }

  #home-video-btn.visible:hover &{
    transform: translateY(-58%);
  }
}

#home-video-btn-content{
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  // display: none;
}

#home-video-btn-title{
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  font-size: 19px;
  line-height: 19px;

  @include media-width($small){
    font-size: 32px;
    line-height: 32px;
  }
}

#home-video-btn-title span{
  display: inline-block;
  font-size: 39px;
  line-height: 39px;
  font-family: $dinblack;
  letter-spacing: .125em;

  @include media-width($small){
    font-size: 62px;
    line-height: 62px;
  }
}

.home-video-btn-play{
  position: absolute;
  top: 62%;
  left: 50%;
  margin-top: -22px;
  margin-left: -24px;

  @include media-width($small){
    margin-top: -34px;
    margin-left: -38px;
  }

  .home-video-btn.visible &{
    transition: transform .6s $easeOutExpo;
  }

  .home-video-btn.visible:hover &{
    transform: translateY(-10px);
  }
}

.home-video-btn-play-hexagon{
  width: 48px;
  height: 42px;
  fill: #fff;

  @include media-width($small){
    width: 77px;
    height: 69px;
  }
}

.home-video-btn-play-picto{
  position: absolute;
  top: 22px - 8px;
  left: 24px - 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 13px;
  border-color: transparent transparent transparent $blue;

  @include media-width($small){
    top: 34px - 11px;
    left: 38px - 5px;
    border-width: 11px 0 11px 20px;
  }
}

#home-video-btn-label{
  position: absolute;
  top: 76.1%;
  left: 0;
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  font-family: $dinmedium;

  @include media-width($small){
    font-size: 24px;
    line-height: 30px;
  }
}

#home-page-content{
  text-align: center;

  @include media-width($small){
    margin-top: -160px - 65px;
  }
}

#home-first-blocks{
  position: relative;
  padding-top: 55px;
  padding-bottom: 400px;
  background-color: #161616;
  z-index: 1;

  @include media-width($small){
    padding-bottom: 280px;
  }

  @include media-width($medium){
    padding-top: 0;
    background-color: transparent;
    padding-bottom: 100px;

    &::after{
      content: "";
      position: absolute;
      top: 225px;
      right: 0;
      bottom: -330px;
      left: 0;
      z-index: -1;
      background-color: #161616;
    }
  }
}

#home-first-blocks-inner{
  text-align: left;

  @include media-width($small){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
  }
}

#home-about,
#home-jobs{
  margin: 0 (40px / 720px) * 100%;
  @media screen and (max-width:767px) {
    transform:none !important;
  }
  // border: 1px solid red;

  @include media-width($small){
    display: inline-block;
    margin: 0;
    vertical-align: bottom;
    transform:inherit;
  }
}

#home-about-body,
#home-jobs-body{
  position: relative;
  background-size: cover;

  &::before{
    content: "";
    display: block;
    width: 100%;
  }
}

#home-about-body-inner,
#home-jobs-body-inner{
  position: absolute;
  bottom: 0;
  left: 0;
}

#home-about-title,
#home-jobs-title{
  margin-bottom: 30px;
  line-height: 1.2;
  padding-bottom: 13px;
}

#home-about,
#home-jobs{
  .block-btn{
    width: 100%;
    height: 90px;
    line-height: 90px;
  }
}

#home-about-body{
  background-color: #000;
  color: #fff;
  background-image: url("../images/home-about.jpg");

  &::before{
    padding-top: (1010px / 660px) * 100%;

    @include media-width($small){
      padding-top: (880px / 660px) * 100%;
    }
  }
}

#home-about{
  @include media-width($small){
    width: $col-7;
    margin-right: $gutter - 0.1%;
  }
}

#home-about-body-inner{
  padding: (60px / 640px) * 100%;

  @include media-width($small){
    padding: ($max-col / 660px) * 100%;
  }
}

#home-about-desc strong{
  font-family: $droid;
  font-weight: 400;
}

#home-about{
  .block-btn{
    padding: 0 (60px / 640px) * 100%;

    @include media-width($small){
      padding: 0 ($max-col / 660px) * 100%;
    }
  }
}

#home-jobs{
  margin-top: 60px;

  @include media-width($small){
    width: $col-5;
    margin-top: 0;
    margin-left: -4px;
  }
}

#home-jobs-body{
  background-color: #fff;
  background-image: url("../images/home-jobs.jpg");

  &::before{
    padding-top: (720 / 460) * 100%;
  }
}

#home-jobs-body-inner{
  bottom: 60px;
  padding: (60px / 640px) * 100%;

  @include media-width($small){
    padding: ($max-col / 460px) * 100%;
  }
}

#home-jobs{
  .block-btn{
    padding: 0 (60px / 640px) * 100%;

    @include media-width($small){
      padding: 0 ($max-col / 460px) * 100%;
    }
  }
}

.home-jobs-video {
  width: 100%;
  padding: 20px 20px 20px (60px / 640px) * 100%;
  display: block;
  line-height: 1.2;
  color: $blue;
  border-top:1px solid #eee;
  font-size:14px;
  font-family:$din, arial, sans-serif;
  position: absolute;
  bottom: 0;
  height: 70px;
  transition: background 0.4s ease-out;
  @include media-width($small) {
    height: 84px;
    padding: 20px 20px 20px ($max-col / 460px) * 100%;
  }
  @include media-width($medium){
    font-size:16px;
  }
  @include media-width($large){
    font-size:18px;
  }

  .home-video-btn-play {
    position: relative;
    float: left;
    top: 0;
    left: 0;
    margin-left:0;
    margin-top:0;
    transform: scale(0.6);
    transform-origin: left top;
    width: 40px;
    @include media-width($small){
      transform: scale(0.5);
      width: 50px;
    }
    @include media-width($medium){
      width: 60px;
    }
    @include media-width($large){
      transform:scale(0.6);
    }

    .home-video-btn-play-hexagon {
      fill:$blue;
      transition:all 0.4s ease-out;
    }

    .home-video-btn-play-picto {
      border-color: transparent transparent transparent #FFF;
    }
  }

  & > span {
    display:block;
    margin-top:0;
    transition:all 0.4s ease-out;
    @include media-width($small){
      margin-top:3px;
    }

    @include media-width($medium){
      margin-top:0;
    }
  }

  &:hover {
    background:rgba(0,0,0,0.05);
    cursor:pointer;

    .home-video-btn-play-hexagon {
      fill:#000;
    }

    & > span {
      color:#000;
    }
  }

}


#home-numbers{
  position: relative;
  text-align: center;
  z-index: 1;
  margin-top: -338px;

  @include media-width($medium){
    display: inline-block;
    width: $grid;
    height: 660px;
    max-width: $max-grid;
    margin-top: -250px;
  }
}

@media all and (min-width:$medium) and (-ms-high-contrast:none)
{
  #home-numbers { margin-top: -150px; } /* IE10 */
  *::-ms-backdrop, #home-numbers { margin-top: -150px; } /* IE11 */
}

#home-numbers-heading{
  position: relative;

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #161616;
  }

  @include media-width($medium){
    display: inline-block;
    width: $col-4 + $gutter;
    height: 100%;
    padding: 120px $col 0;
    background-color: #f2f2f2;

    &::before{
      content: none;
    }
  }
}

.underlined-title.key-fact-title {
  color:#FFF;
}

#home-numbers-heading-inner{
  position: relative;
  padding: (60px / 640px) * 100%;
  margin: 0 (40px / 720px) * 100%;
  background-color: #f2f2f2;

  @include media-width($medium){
    padding: 0;
    margin: 0;
  }
}

#home-numbers-title{
  margin-bottom: 60px;

  @include media-width($medium){
    margin-bottom: 80px;
  }
}

#home-numbers-items{
  margin: 0 (40px / 720px) * 100%;
  line-height: 0;
  font-size: 0;
  vertical-align: top;

  @include media-width($medium){
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-left: -4px - 1px; // -1px fix percent size antialiasing (heading block)
    margin-right: 0;
  }
}

.home-numbers-item{
  position: relative;
  display: inline-block;
  width: 50%;
  min-height: 210px;
  padding: 39px ((60px / 640px) * 100%) 0;
  vertical-align: top;
  text-align: center;
  color: #fff;
  background-color: $blue;

  @include media-width($max: $medium - 1){
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6){
      background-color: #3aafe5;
    }
  }

  @include media-width($medium){
    width: 33.33%;
    height: 50%;
    padding: 155px (($max-col / 760px) * 100%) 0;

    &:nth-child(3n + 1){
      margin-left: 0;
    }

    &:nth-child(even){
      background-color: #3aafe5;
    }

    &:nth-child(n + 4){
      padding-top: 55px;
    }
  }

  &:hover{
    z-index: 2;
  }

  .touchevents &{
    cursor: pointer;
  }
}

.home-numbers-item-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  box-shadow: 0px 5px 12px rgba(0,0,0,.3);
  visibility: hidden;
  background-color: inherit;
  // transition: transform .7s $easeOutExpo;

  .home-numbers-item:hover &{
    transform: scale(1.08,1.08);
    transition: transform .5s .2s $easeOutExpo;
    visibility: visible;
  }

  &::after{
    content: "";
    position: absolute;
    top: 39px;
    left: 50%;
    width: 38px;
    height: 38px;
    margin-left: -19px;
    background-color: #fff;
    border-radius: 50%;

    .home-numbers-item:hover &{
      transition: transform .5s;
      transform: scale(13,13);
    }

    @include media-width($small){
      .home-numbers-item:hover &{
        transform: scale(15,15);
      }
    }

    @include media-width($medium){
      top: 60px;
      width: 60px;
      height: 60px;
      margin-left: -30px;

      .home-numbers-item:nth-child(n + 4) &{
        top: auto;
        bottom: 60px;
      }

      .home-numbers-item:hover &{
        transform: scale(11,11);
      }
    }
  }
}

.home-numbers-item-picto{
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  margin-bottom: 18px;
  line-height: 36px;
  background-color: #fff;
  border-radius: 50%;
  backface-visibility: hidden;
  transition: transform .6s $easeOutExpo;

  @include media-width($medium){
    position: absolute;
    top: 60px;
    left: 50%;
    display: block;
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    margin-left: -30px;
    line-height: 60px;

    .home-numbers-item:nth-child(n + 4) &{
      top: auto;
      bottom: 60px;
    }
  }

  .home-numbers-item:hover &{
    transform: scale(1.5,1.5);
    transition: transform .5s .1s $easeOutExpo;
  }
}

.home-numbers-item-picto svg{
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

#home-numbers-item-picto-calendar svg{
  width: 20px;
  height: 15px;

  @include media-width($medium){
    width: 30px;
    height: 23px;
  }
}

#home-numbers-item-picto-chart svg{
  width: 13px;
  height: 14px;

  @include media-width($medium){
    width: 20px;
    height: 22px;
  }
}

#home-numbers-item-picto-earth svg{
  width: 20px;
  height: 19px;

  @include media-width($medium){
    width: 30px;
    height: 30px;
  }
}

#home-numbers-item-picto-euro svg{
  width: 12px;
  height: 14px;

  @include media-width($medium){
    width: 17px;
    height: 23px;
  }
}

#home-numbers-item-picto-rulers svg{
  width: 16px;
  height: 14px;

  @include media-width($medium){
    width: 25px;
    height: 22px;
  }
}

#home-numbers-item-picto-woman svg{
  width: 20px;
  height: 20px;

  @include media-width($medium){
    width: 30px;
    height: 30px;
  }
}

.home-numbers-item-value{
  padding-bottom: 6px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -.05em;
  transition: transform .5s $easeOutExpo;

  @include media-width($large){
    font-size: 72px;
    line-height: 72px;
  }

  .home-numbers-item:hover &{
    transform: translateY(-20px);
    color: $blue;
  }
}

.home-numbers-item-txt{
  font-size: 10px;
  line-height: 12px;
  transition: transform .5s $easeOutExpo;

  @include media-width($small){
    font-size: 12px;
    line-height: 14px;
  }

  @include media-width($medium){
    font-size: 14px;
    line-height: 16px;
  }

  @include media-width($large){
    font-size: 15px;
    line-height: 18px;
  }

  .home-numbers-item:hover &{
    transition: transform .5s .1s $easeOutExpo;
    transform: translateY(-20px);
    color: #666;
  }
}

.home-numbers-item-link{
  position: absolute;
  bottom: 15px;
  left: 50%;
  display: block;
  z-index: 2;
  color: #000;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-family: $din;
  letter-spacing: .05em;
  transform: translate(-50%, 15px);
  visibility: hidden;
  backface-visibility: hidden;
  white-space: nowrap;

  .home-numbers-item:hover &{
    visibility: visible;
    transform: translate(-50%,0px);
    transition: transform .5s .2s $easeOutExpo, visibility 0s .2s;
  }

  &::before,
  &::after{
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
  }

  &::before{
    transform-origin: right;
    transform: scaleX(0);
    background-color: #000;
    transition: transform .5s $easeOutExpo;
  }

  &:hover::before{
    transform-origin: left;
    transform: scaleX(1);
    transition: transform .5s $easeInOutExpo;
  }

  &::after{
    transform-origin: left;
    background-color: $blue;
    transition: transform .5s $easeInOutExpo;
  }

  &:hover::after{
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s $easeOutExpo;
  }
}

#home-articles{
  padding-top: 40px;
  padding-bottom: 60px;
  margin: 0 (40px / 720px) * 100%;

  @include media-width($small){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
    padding-top: 110px;
    padding-bottom: 115px;
  }
  @include media-width($medium) {
    padding-top: 210px;
  }
}

#home-articles-title{
  margin-bottom: 60px;

  @include media-width($small){
    margin-bottom: 105px;
  }
}

