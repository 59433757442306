.level-2-content-block{

  &.grey-deploiement-approche{
    background-image: url("../images/expertise-deploiement-approches.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
  }

  &.grey-formation{
    background-image: url("../images/formation.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
  }

  &.grey{
    background-color: #e6e6e6;
  }

  &.black-change-graph{
    background-image: url("../images/conduite-changement.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
  }

  &.black {
    background:#000;
  }
}

