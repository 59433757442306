.block-btn{
  position: relative;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  text-transform: uppercase;
  color: #fff;
  font-family: $dinbold;
  letter-spacing: .1em;
  background-color: $blue;

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #349ccc;
    opacity: 0;
    transition: opacity .6s;
  }

  &:hover::before{
    opacity: 1;
    transition: opacity 0s .7s;
  }
}

// wrapper for block button content: fix flex layout in <button> (FF)
.block-btn-inner{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.block-btn-picto{
  position: relative;
  display: inline-block;
  width: 35px;
  height: 30px;
  margin-left: auto;
  vertical-align: middle;
}

.block-btn-picto-hexagon{
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  fill: #349ccc;

  .block-btn:hover &{
    transition: transform .7s .1s;
    transform:scale(60,60);
  }
}

.block-btn-picto-arrow{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transition: transform .5s $easeOutExpo;

  .block-btn:hover &{
    transition: transform .7s .1s $easeOutExpo;
    transform:translateX(10px);
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -5px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    backface-visibility: hidden;
  }

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 14px;
    height: 2px;
    margin-top: -1px;
    margin-left: -12px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right top;
    transition: transform .3s $easeOutExpo;

    .block-btn:hover &{
      transition: transform .7s .1s $easeOutExpo;
      transform: scaleX(1);
    }
  }
}

.block-btn-label{
  position: relative;
  z-index: 2;
  color: #FFF;
}

