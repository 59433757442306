html{
  width: 100%;
  height: 100%;
}

body{
  width: 100%;
  min-height: 100%;
  font-family: $dinlight;
  font-weight: 300;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #161616;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 400;
  font-family: $din;
}

a{
  text-decoration: none;

  &[href^=tel]{
    cursor: default;
  }

  img{
    border: none;
  }
}

.clear {
  clear:both;
}

input,
textarea,
button{
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: 0;

  &:disabled{
    cursor: default;
  }
}

.content-wrapper{
  margin: 0 $margin;
  text-align: center;

  @include media-width($small){
    margin: 0;
  }
}

.content-inner{
  text-align: left;

  @include media-width($small){
    display: inline-block;
    width: $grid;
    max-width: $max-grid;
  }
}

.content-paragraph{
  margin-bottom: 30px;
}

.content-image{
  width: 100%;
  height: auto;
}

.content-link{
  font-family: $dinmedium;
  color: inherit;
  background-position: 0 bottom;
  background-size: 1px 1px;
  background-image: linear-gradient($blue 50%, $blue 50%);
  background-repeat: repeat-x;
}

.content-list{
  padding-left: 20px;
}

.content-list-item{
  position: relative;
  margin-bottom: 15px;

  @include media-width($small){
    margin-bottom: 30px;
  }

  &::before{
    content: "";
    position: absolute;
    top: 11px;
    left: -20px;
    width: 6px;
    height: 7px;
    background-color: $blue;
    border-radius: 50%;
  }
}

.underlined-title{
  position: relative;
  display: inline-block;
  font-family: $droid;
  font-style: italic;
  font-size: 30px;
  line-height: 68px;
  letter-spacing: .025em;

  @include media-width($small){
    font-size: 33px;
  }

  @include media-width($large){
    font-size: 40px;
  }

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $blue;
  }
}

.active-underlined-title{
  overflow: hidden;

  &::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 2;
    background-color: currentColor;
    transform-origin: right;
    transition: transform .7s $easeOutExpo, visibility .6s;
    transform: scaleX(0);
    visibility: hidden;
  }

  &:hover::before{
    transform: scaleX(1);
    transform-origin: left;
    transition: transform .5s $easeInOutExpo;
    visibility: visible;
  }

  &::after{
    transform-origin: left;
    transition: transform .5s $easeInOutExpo;
  }

  &:hover::after{
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .5s $easeOutExpo;
  }
}

.underlined-title a{
  color: inherit;
}
