.nav-opened{
  overflow: hidden;
}

#nav{
  text-transform: uppercase;

  &.scroll-active{
    &.hidden{
      transition: visibility 0s .9s;
      visibility: hidden !important;
    }

    .is-loading &{
      visibility: inherit !important;
      transition: none;
    }
  }

  @include media-width($medium){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 20;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.3);

    .js &{
      position: fixed;
      visibility: hidden;
    }

    &.scroll-active.hidden{
      transition: opacity .9s, visibility 0s .9s;
      opacity: 0;
      visibility: hidden !important;
    }

    &.scroll-active,
    .is-loading &.scroll-active{
      transition: opacity .4s;
      opacity: 1;
      visibility: inherit !important;
    }

    &::before,
    &.opaque.hidden::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #161616;
      opacity: 0;
      transition: opacity .4s;
    }

    &.opaque::before,
    .is-loading &.opaque.hidden::before{
      transition-duration: .9s;
      opacity: 1;
    }
  }
}

#nav-inner{
  @include media-width($medium){
    display: inline-block;
    width: $grid;
    height: 100%;
    max-width: $max-grid;
    text-align: left;
  }
}

#nav-bar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-bar-height;
  z-index: 21;
  background-color: #000;

  @include media-width($max: $medium - 1){
    #nav.scroll-active.hidden &{
      transition-duration: .9s;
      opacity: 0;
    }

    #nav.scroll-active &,
    .is-loading #nav.scroll-active &{
      opacity: 1;
      transition: opacity .4s;
    }
  }

  @include media-width($medium){
    position: relative;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    width: $col-2;
    padding-right: 24px;
    height: 100%;
    background-color: transparent;
  }
}

@media print{
  #nav-bar{
    position: static;
  }
}

#nav-logo{
  display: block;
  width: 112px;
  height: 25px;
  margin-top: 15px;
  margin-left: (50px / 720px) * 100%;
  vertical-align: top;

  @include media-width($medium){
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
    vertical-align: middle;
    margin-top:-5px;
  }

  @include media-width($large){
  }
}

#nav-logo svg{
  display: block;
  padding: 1px; // fix a cut appearing with some resolutions
}

#nav-toggle{
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  height: 60px;

  .nav-opened &{
    background-color: #333;
  }

  @include media-width($medium){
    display: none;
  }
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after{
  content: "";
  position: absolute;
  display: block;
  width: 22px;
  height: 3px;
  background: #fff;
  // transition: all 500ms $easeOutExpo;

  // #nav.opened &{
  //   background: $brown-light;
  // }
}

#nav-toggle span{
  top: 30px - 3px;
  left: 31px - 11px;
}

#nav-toggle span:before{
  top: -8px;
  left: 0;
}

#nav-toggle span:after{
  bottom: -8px;
  left: 0;
}

#nav-items{
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 60px;
  z-index: 20;
  visibility: hidden;
  background-color: #333;

  .nav-opened &{
    visibility: inherit;
  }

  @include media-width($max: $medium - 1){
    overflow: auto;
  }

  @include media-width($medium){
    position: relative;
    top: 0;
    display: inline-block;
    display: inline-flex;
    width: $col-10 + $gutter - 0.05%;
    height: 100%;
    padding-bottom: 0;
    margin-left: -4px; // fix display inline-block extra spacing
    visibility: inherit;
    vertical-align: top;
    background-color: transparent;
  }
}

@media print{
  #nav-items{
    display: none;
  }
}

#nav-pages-wrapper{
  width: 100%;

  @include media-width($medium){
    display: inline-block;
    display: inline-flex;
    width: auto;
    height: 100%;
    padding-right: 10px;
    flex-grow: 2;
    border-right: 1px solid rgba(255,255,255,.3);
  }

  @include media-width($large){
    padding-right: 15px;
  }
}

.js #nav-pages-line{
  @include media-width($medium){
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 4px;
    opacity: 0;
    background-color: #fff;
    transform-origin: left top;
  }
}

#nav-pages{
  @include media-width($medium){
    display: flex;
    width: 100%;
  }
}

.nav-page{
  width: 100%;
  height: 65px;
  line-height: 65px;
  border-bottom: 1px solid #484848;

  @include media-width($medium){
    position: relative;
    float: left;
    width: auto;
    height: 100%;
    flex-grow: 1;
    line-height: inherit;
    border-bottom: 0;
    text-align: center;
  }

  @include media-width($xlarge){
    letter-spacing: .05em;
  }
}

.nav-page-link{
  display: block;
  height: 100%;
  padding-left: (50px / 720px) * 100%;
  color: #fff;
  font-size: 13px;
  text-decoration: none;

  @include media-width($max: $medium - 1){
    .nav-page.selected &{
      background-color: #fff;
      color: #333;
    }
  }

  @include media-width($medium){
    position: relative;
    padding-left: 0;
    font-size: 12px;
    z-index: 2;


    .nav-page.selected &{
      cursor: default;
    }
  }

  @include media-width($large){
    font-size: 14px;
  }
}

.nav-page-link-label{
  @include media-width($medium){
    position: relative;
    display: inline-block;
    height: 100%;
    // overflow: hidden;

    .nav-page.selected &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #fff;

      .js &{
        content: none;
      }
    }

    // &::after{
    //   content: "";
    //   position: absolute;
    //   top: 50px;
    //   left: 0;
    //   width: 100%;
    //   height: 2px;
    //   transform: scaleX(0);
    //   background-color: $blue;
    //   transition: transform .5s $easeOutExpo;
    //   transform-origin: right top;

    //   .nav-page.right-to-left &{
    //     transform-origin: left top;
    //   }

    //   .nav-page-link:hover &{
    //     transform: scaleX(1);
    //     transform-origin: left top;
    //   }

    //   .nav-page.right-to-left .nav-page-link:hover &{
    //     transform-origin: right top;
    //   }

    //   .nav-page.selected .nav-page-link:hover &{
    //     transform: scaleX(0);
    //     transform-origin: right top;
    //   }

    //   .nav-page.right-to-left.selected .nav-page-link:hover &{
    //     transform-origin: left top;
    //   }
    // }
  }
}

.nav-page-link-label-over-wrapper{
  display: none;

  @include media-width($medium){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.nav-page-link-label-over-inner{
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  animation: nav-page-label-inner-roll-out .5s $easeOutExpo forwards;
  visibility: hidden;
  transition: visibility .5s;

  .nav-page.selected &{
    visibility: hidden !important;
  }

  .nav-page.right-to-left &{
    animation-name: nav-page-label-inner-roll-out-reverse;
  }

  .nav-page-link:hover &{
    animation: nav-page-label-inner-roll-over .5s $easeOutExpo forwards;
    visibility: visible;

    .nav-page.right-to-left &{
      animation-name: nav-page-label-inner-roll-over-reverse;
    }
  }

  .nav-page.selected .nav-page-link:hover &{
    animation-name: nav-page-label-inner-roll-out;
  }

  .nav-page.right-to-left.selected .nav-page-link:hover &{
    animation-name: nav-page-label-inner-roll-out-reverse;
  }
}

@keyframes nav-page-label-inner-roll-over{
  from{
    transform: translateX(-100%);
  }

  to{
    transform: translateX(0);
  }
}

@keyframes nav-page-label-inner-roll-over-reverse{
  from{
    transform: translateX(100%);
  }

  to{
    transform: translateX(0);
  }
}

@keyframes nav-page-label-inner-roll-out{
  to{
    transform: translateX(100%);
  }
}

@keyframes nav-page-label-inner-roll-out-reverse{
  to{
    transform: translateX(-100%);
  }
}

.nav-page-link-label-over{
  display: block;
  color: $blue;
  animation: nav-page-label-roll-out .5s $easeOutExpo forwards;

  .nav-page.right-to-left &{
    animation-name: nav-page-label-roll-out-reverse;
  }

  .nav-page-link:hover &{
    animation: nav-page-label-roll-over .5s $easeOutExpo forwards;

    .nav-page.right-to-left &{
      animation-name: nav-page-label-roll-over-reverse;
    }
  }

  .nav-page.selected .nav-page-link:hover &{
    animation-name: nav-page-label-roll-out;
  }

  .nav-page.right-to-left.selected .nav-page-link:hover &{
    animation-name: nav-page-label-roll-out-reverse;
  }
}

@keyframes nav-page-label-roll-over{
  from{
    transform: translateX(100%);
  }

  to{
    transform: translateX(0);
  }
}

@keyframes nav-page-label-roll-over-reverse{
  from{
    transform: translateX(-100%);
  }

  to{
    transform: translateX(0);
  }
}

@keyframes nav-page-label-roll-out{
  to{
    transform: translateX(-100%);
  }
}

@keyframes nav-page-label-roll-out-reverse{
  to{
    transform: translateX(100%);
  }
}

.nav-subpages{
  display: none;

  @include media-width($medium){
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    padding-top: 80px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s, visibility 0s .3s;

    .nav-page:hover &{
      visibility: visible;
      opacity: 1;
      transition: opacity .4s;
      width: 100%;
    }

    &::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: -5px;
      left: 0;
      background-color:#282828;
    }

    &::after{
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #FFF;
    }
  }
}

.nav-subpage{
  position: relative;
  line-height: 0;
}

.nav-subpage-link{
  position: relative;
  display: block;
  padding: 20px 18px 20px;
  font-size: 13px;
  line-height: 14px;
  text-align: left;
  color: rgba(255,255,255,.7);
  letter-spacing: 0;
  text-transform: none;
  font-weight: 400;
  background-color: #282828;
  border-top: 1px solid rgba(255,255,255,.2);
  z-index: 1;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  .nav-subpage.selected &{
    opacity: 1;
    cursor: default;
    color: #fff;
  }

  &:hover {
    color:#FFF;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .7s $easeOutExpo;
  }

  .nav-subpage.selected &::before{
    content: none;
  }

  &:hover::before{
    transform: scaleX(1);
    transform-origin: left;
    transition-duration: .5s;
  }
}

#nav-lang{
  @include media-width($medium){
    display: inline-block;
    height: 100%;
    margin-right: 10px;
    vertical-align: top;
  }
}

#nav-lang-btn{
  display: none;

  @include media-width($medium){
    position: relative;
    display: inline-block;
    height: 100%;
    padding: 0px 23px 0 15px;
    text-transform: uppercase;
    font-size: 11px;
    font-family: $din;
    color: #fff;
    line-height: inherit;
    vertical-align: top;
    letter-spacing: .1em;
    overflow: hidden;

    #nav-lang:hover &{
      color: #000;
    }

    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: -1;
      animation: right-to-left.5s forwards $easeOutExpo;
      visibility: hidden;
      transition: visibility .5s;

      #nav-lang:hover &{
        animation: left-to-right .5s forwards $easeOutExpo;
        visibility: visible;
      }
    }
  }

  @include media-width($large){
    padding: 0px 33px 0px 30px;
    font-size: 13px;
  }

  &::after{
    content: "";
    position: absolute;
    top: 46%;
    right: 16px - 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 3px 0 3px;
    border-color: #fff transparent transparent transparent;

    @include media-width($large){
      right: 20px - 4px;
    }

    #nav-lang:hover &{
      border-color: #000 transparent transparent transparent;
    }
  }
}

#nav-lang-items{
  @include media-width($medium){
    visibility: hidden;
    // background-color: #fff;

    #nav-lang:hover &{
      visibility: inherit;
    }
  }
}

.nav-lang-item-btn{
  display: block;
  padding-left: (50px / 720px) * 100%;
  text-align: center;
  font-size: 15px;
  line-height: 70px;
  text-align: left;
  color: rgba(255,255,255,.4);
  z-index: 1;

  @include media-width($medium){
    position: relative;
    padding-left: 0;
    font-size: 11px;
    line-height: 80px;
    text-align: center;
    color: #000;
    overflow: hidden;

    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #fff;
      transform: translateX(-100%);
      transition: transform .6s .1s $easeOutExpo, background-color .4s;

      #nav-lang:hover &{
        transform: translateX(0);
      }
    }

    &:hover::before{
      background-color: #e6e6e6;
    }
  }

  @include media-width($large){
    font-size: 13px;
  }
}

.nav-lang-item-btn-short-label{
  display: none;

  @include media-width($medium){
    display: block;
    visibility: hidden;

    #nav-lang:hover &{
      visibility: inherit;
      transition: visibility 0s .15s;
    }
  }
}

.nav-lang-item-btn-long-label{
  @include media-width($medium){
    display: none;
  }
}

#nav-worlwide-wrapper{
  margin: 0 (50px / 720px) * 100%;

  @include media-width($medium){
    display: inline-block;
    width: auto;
    height: 100%;
    margin: 0;
    vertical-align: top;
  }
}

#nav-worldwide{
  display: none;
  width: 100%;
  color: #fff;
  line-height: 40px;
  border: 1px solid rgba(255,255,255,.3);
  text-align: center;

  @include media-width($medium){
    position: relative;
    padding: 2px 9px 0 9px;
    margin-top: -2px;
    vertical-align: middle;
    font-size: 10px;
    line-height: 24px;
    z-index: 1;
    overflow: hidden;

    &:hover{
      color: #000;
    }

    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      animation: right-to-left .5s forwards $easeOutExpo;
      z-index: -1;
      visibility: hidden;
      transition: visibility .5s;
    }

    &:hover::before{
      animation: left-to-right .5s both $easeOutExpo;
      visibility: visible;
    }
  }

  @include media-width($large){
    font-size: 11px;
  }
}

