#loading{
  position: fixed;
  top: $nav-bar-height;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 100;
  overflow: hidden;
  visibility: hidden;

  @include media-width($medium){
    top: 79px;
  }
}

#loading-inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#loading-progress{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  background-color: $blue;
}
