#team-block-content{
  padding-top: 70px;
  padding-bottom: 50px;
}

.consultant-title {
  margin-top:30px;
}

#team-list{
  padding-left: 0;
}

.team-person{
  position: static !important;
  margin-bottom: 30px !important;
  vertical-align:top;

  @include media-width($small){
    display: inline-block;
    width: $col-6;
    margin-right: -4px;
    margin-left: $gutter;
    margin-bottom: 0 !important;

    &:nth-of-type(2n+1){
      margin-left: 0;
    }
  }

  @include media-width($medium){
    width: $col-4;

    &:nth-of-type(2n+1){
      margin-left: $gutter;
    }

    &:nth-of-type(3n+1){
      margin-left: 0;
    }
  }

  &::before{
    content: none !important;
  }
}

.team-person-btn{
  position: relative;
  padding-bottom: 20px;
  cursor: pointer;

  @include media-width($small){
    padding-bottom: 40px;
  }
}

.team-person-pic-wrapper{
  position: relative;
}

.team-person-pic{
  position: relative;
  display: block;
  z-index: 2;
  backface-visibility: hidden;
  transition: opacity .3s;

  .team-person-btn:hover .team-person-pic-wrapper.loaded &{
    opacity: 0;
  }
}

.team-person-pic-over{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.team-person-id{
  position: relative;
  padding: 24px (30px / 360px) * 100% 20px;
  margin: -70px (30px / 360px) * 100% 0;
  text-transform: uppercase;
  text-align: center;
  font-family: $din;
  background-color: #000;
  z-index: 2;
  transition: background-color .6s;
  min-height:150px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  .team-person-btn:hover &,
  .team-person.selected &{
    background-color: #f2f2f2;
    transition-duration: .2s;
  }
}

.team-person-id-bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: -40px;
  left: 0;
  opacity: 0;
  transition: opacity .6s;
  overflow: hidden;
  backface-visibility: hidden;

  .team-person.current &{
    opacity: 1;
  }

  &::before{
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background-color: #f2f2f2;
    transform-origin: top;
    transform: translateY(-40px);
    transition: transform .6s $easeInOutExpo;

    .team-person.selected &{
      transform: translateY(0);
      transition: transform .8s $easeOutExpo;
    }

    #team-list.has-previous-item .team-person.selected &{
      transition-delay: .2s;
    }
  }
}

.team-person-role{
  position: relative;
  padding-bottom: 3px;
  font-size: 16px;
  line-height: 18px;
  color: $blue;
}

.team-person-name{
  position: relative;
  padding-bottom: 13px;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  padding-top:7px;
  font-family: $dinbold;

  .team-person-btn:hover &,
  .team-person.current &{
    color: #000;
  }
}

.team-person-since{
  position: relative;
  font-size: 12px;
  line-height: 12px;
  color:#AAA;
}

.team-person-infos{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 70px ((40px / 720px) * 100%) (70px + 50px);
  background-color: #f2f2f2;
  display: none;
  overflow: hidden;
  cursor: default;

  @include media-width($max: $medium - 1){
    margin: 0;
  }

  @include media-width($medium){
    padding: 70px 0;
  }

  &::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: #fff;

    @include media-width($small){
      height: 50px;
    }
  }
}

.team-person-infos-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.team-person-infos .content-inner{
  margin-bottom: 0;
}

